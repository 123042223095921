import React from 'react'
import Videos from '../Videos';
import { useHistory } from 'react-router-dom'

const DBDA_CA = React.memo(() => {

    const history = useHistory();

    const nextRoute = () => {
        history.push('/question4')
    }

    const title = "CA-Closure ability";
    const url = "https://careers4me.in/videos/Closure_ability.mp4";

    return (
        <Videos title={title} url={url} onPress={nextRoute} />
    )
});

export default DBDA_CA