import { React, Fragment } from 'react'
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';

const UserDetail = (props) => {

    const { values, handleChange, handleFileChange, title } = props;

    return (
        <Fragment>
            <Typography variant="h6" color="inherit" style={{ margin: "20px" }}>{title}</Typography>
            <form encType="multipart/form-data">
                <Grid container spacing={3}>
                    <Grid item xs={12} sm={4}>
                        <TextField
                            name="name"
                            label="Name"
                            type="text"
                            variant="filled"
                            readOnly
                            value={JSON.parse(localStorage.getItem('userData')).name}
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <TextField
                            name="dob"
                            label="Date Of Birth"
                            type="date"
                            variant="filled"
                            value={values.dob}
                            onChange={handleChange}
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <TextField
                            name="city"
                            label="City"
                            type="text"
                            variant="filled"
                            value={values.city}
                            onChange={handleChange}
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <TextField
                            name="state"
                            label="State"
                            type="text"
                            variant="filled"
                            value={values.state}
                            onChange={handleChange}
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <TextField
                            name="country"
                            label="Country"
                            type="text"
                            variant="filled"
                            value={values.country}
                            onChange={handleChange}
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <TextField
                            name="school"
                            label="School"
                            type="text"
                            variant="filled"
                            fullWidth
                            value={values.school}
                            onChange={handleChange}
                        />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <TextField
                            name="board"
                            label="Board"
                            type="text"
                            variant="filled"
                            fullWidth
                            value={values.board}
                            onChange={handleChange}
                        />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <TextField
                            label="Age"
                            name="age"
                            type="text"
                            variant="filled"
                            fullWidth
                            value={values.age}
                            onChange={handleChange}
                        />
                    </Grid>
                    <Grid item xs={12} sm={4} style={{ textAlign: "justify" }}>
                        <FormControl component="fieldset">
                            <FormLabel component="legend">Gender</FormLabel>
                            <RadioGroup aria-label="gender" name="gender" value={values.gender} onChange={handleChange} style={{ display: 'inline' }}>
                                <FormControlLabel value="Male" control={<Radio />} label="Male" />
                                <FormControlLabel value="Female" control={<Radio />} label="Female" />
                                <FormControlLabel value="Other" control={<Radio />} label="Other" />
                            </RadioGroup>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <TextField
                            label="Degree College"
                            name="degree_college"
                            type="text"
                            variant="filled"
                            fullWidth
                            value={values.degree_college}
                            onChange={handleChange}
                        />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <TextField
                            label="Masters College"
                            name="masters_college"
                            type="text"
                            variant="filled"
                            fullWidth
                            value={values.masters_college}
                            onChange={handleChange}
                        />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <TextField
                            label="Organizations"
                            name="organizations"
                            type="text"
                            variant="filled"
                            fullWidth
                            value={values.organizations}
                            onChange={handleChange}
                        />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <TextField
                            label="Department"
                            name="department"
                            type="text"
                            variant="filled"
                            fullWidth
                            value={values.department}
                            onChange={handleChange}
                        />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <TextField
                            label="Title"
                            name="title"
                            type="text"
                            variant="filled"
                            fullWidth
                            value={values.title}
                            onChange={handleChange}
                        />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <TextField
                            label="Profile Image"
                            name="profile_image"
                            type="file"
                            variant="filled"
                            fullWidth
                            onChange={handleFileChange}
                        />
                    </Grid>
                </Grid>
            </form>
        </Fragment >
    )
}

export default UserDetail
