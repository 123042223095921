import React from 'react'
import { useHistory } from 'react-router-dom';
import Videos from '../Videos';

const End = React.memo(() => {

    const title = "End";
    const url = "https://careers4me.in/videos/End.mp4";

    const adminRedirect = () => {
        window.location = "https://careers4me.in/console/"
    }
    
    const history = useHistory();
        
    const checkValidQ = JSON.parse(sessionStorage.getItem('Qpoints'));

    React.useEffect(() => {
    
        if(checkValidQ.pq === '0') {
            history.push('/personalitytest');
        }
        
    }, [checkValidQ,history])

    return (
        <Videos title={title} url={url} onPress={adminRedirect}/>
    )
});

export default End