import React,{ useState, Fragment, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import UserDetail from './UserDetail'
import Payment from './Payment'
import Price from './Price'
import Constants from '../Constants'
// import UserId from '../Quiz/UserId';
import useProfile from '../Hooks/useProfile'
import useFetch from '../Hooks/useFetch'
import { toast } from 'react-toastify'
import { CssBaseline, Container, Paper, Stepper, Step, StepLabel, Button } from '@material-ui/core';
import Topbar from '../Topbar';
import Footer from '../Footer'
import axios from 'axios';

const Dashboard = React.memo(() => {
    
    const history = useHistory();

    const { data: userDetailData } = useFetch(Constants.url + 'auth/getUserDetail');
    const { data: payCheck } = useFetch(Constants.url + 'questions/attemp_check/payments');

    const [Loading, setLoading] = useState(false);

    const { userData, logout, handleProfileChange, updateProfile,
        anchorEl, open, handleMenu, handleClose,
        openmodal, handleOpenModal, handleCloseModal } = useProfile();
        
    const [profilestate, setProfileState] = useState({
        dob:"",
        city:"",
        state:"",
        country:"",
        school: "",
        board: "",
        age: "",
        gender: "",
        degree_college: "",
        masters_college: "",
        organizations: "",
        department: "",
        title: "",
        profile_image:""
    });
    
    const [price,setPrice] = useState({
        amount: ""
    });
    const [dprice,setDPrice] = useState('');
    // const [checkIds,setCheckIds] = useState('');

    const [activeStep, setActivestep] = useState(1);
    // const labelValues = ['User Details','Pricing', 'Payment'];
    const [steplabel, setSteplabel] = useState([]);

    useEffect(() => {        
        // getUserDetail();
        setSteplabel(['User Details','Pricing', 'Payment']);
        setLoading(true);
        if(userDetailData && userDetailData.status === 'success') {
            setProfileState({
                dob:userDetailData.data.dob ? userDetailData.data.dob : '',
                city:userDetailData.data.city ? userDetailData.data.city : '',
                state:userDetailData.data.state ? userDetailData.data.state : '',
                country:userDetailData.data.country ? userDetailData.data.country : '',                
                school: userDetailData.data.school ? userDetailData.data.school : '',
                // standard: userDetailData.data.standard ? userDetailData.data.standard : '',
                board: userDetailData.data.board ? userDetailData.data.board : '',
                age: userDetailData.data.age ? userDetailData.data.age : '',
                gender: userDetailData.data.gender ? userDetailData.data.gender : '',
                degree_college: userDetailData.data.degree_college ? userDetailData.data.degree_college : '',
                masters_college: userDetailData.data.masters_college ? userDetailData.data.masters_college : '',
                organizations: userDetailData.data.organizations ? userDetailData.data.organizations : '',
                department: userDetailData.data.department ? userDetailData.data.department : '',
                title: userDetailData.data.title ? userDetailData.data.title : ''
            });
            
            var discount = userDetailData.data.discount;
            if(discount === '0') {
                setLoading(false);
                history.push('/quiz_selection');
            }
            else {
                setLoading(false);
                setDPrice(discount)
            }
        }
        else {
            setProfileState({
                dob:"",
                city:"",
                state:"",
                country:"",
                school: "",
                // standard: "",
                board: "",
                age: "",
                gender: "",
                degree_college: "",
                masters_college: "",
                organizations: "",
                department: "",
                title: "",
            });
            // setLoading(false);
        }
       
        if(payCheck.check === '1') {
            history.push('/quiz_selection');
        }
        else {
            // history.push('/');
            setLoading(false);
        }
        
    }, [userDetailData,payCheck,history])        

    //Next Step
    const nextStep = () => {
        
        const { dob, state, city, country, school, board, age, gender, degree_college, masters_college, organizations, department, title, profile_image } = profilestate;
        
        const formdata = new FormData();
         
        formdata.append('dob', dob);
        formdata.append('city', city);
        formdata.append('state', state);
        formdata.append('country', country);
        formdata.append('school', school);
        formdata.append('board', board);
        formdata.append('age', age);
        formdata.append('gender', gender);
        formdata.append('degree_college', degree_college);
        formdata.append('masters_college', masters_college);
        formdata.append('organizations', organizations);
        formdata.append('department', department);
        formdata.append('title', title);
        formdata.append('profile_image', profile_image);
        
        if (activeStep === 1) {
            if (dob === '' || state === '' || city === '' || country === '' || school === '' || board === '' || age === '' || gender === '') {
                toast.error('All Fields Are Required');
                return false;
            }
            else {
                setLoading(true);
                axios.post(Constants.url + 'auth/addUserDetail', formdata, 
                {
                    headers : {
                        'Content-type': 'application/json',
                        'Authorization': `Bearer ${JSON.parse(localStorage.getItem('token'))}`
                    }                    
                })
                .then((res) => {
                    console.log(res);
                    if (res.data.status === 'success') {
                        setLoading(false);
                        toast.success(res.data.message);
                        setActivestep(activeStep + 1);
                    }
                    else {
                        setLoading(false);
                        toast.error(res.data.message);
                        return false;
                    }
                })
                .catch((err) => {
                    console.log(err);
                    return false;
                });
                
            }
        }
        if (activeStep === 2) {
            if(price.amount === "") {
                toast.error('Select Price');
                return false;
            }
            setActivestep(activeStep + 1);
            sessionStorage.setItem("price", price.amount);
        }
        
    };

    //Previous Step
    const previousStep = () => {
        setActivestep(activeStep - 1);
    };

    // HandleChagen Input
    const handleChange = (e) => {
        setProfileState({
            ...profilestate,
            [e.target.name]: e.target.value
        });
    }

    const handleFileChange = (e) => {
        setProfileState({
            ...profilestate,
            profile_image: e.target.files[0]
        })
    }

    const handleChangeprice = (e) => {
        setPrice({
            ...price,
            [e.target.name]: e.target.value
        });
    }

    //Get Steps
    const getStepContent = (step) => {

        switch (step) {
            case 1:
                return (
                    <UserDetail
                        title="User Detail Form"
                        handleChange={handleChange}
                        handleFileChange={handleFileChange}
                        values={profilestate}
                    />
                )
            case 2:
                 return (
                     <Price
                        handleChange={handleChangeprice}
                        title="Select Amount"
                        values={price}
                        price={dprice}
                     />
                 )
             case 3:
                 return (
                     <Payment
                         title="Payment"
                     />
                 )
            default:
                return 'Unknown step';
        }
    }

    return (
        <Fragment>
            <CssBaseline />
            <Container maxWidth="lg">
                <Topbar
                    userData={userData}
                    handleMenu={handleMenu}
                    anchorEl={anchorEl}
                    open={open}
                    handleClose={handleClose}
                    handleOpenModal={handleOpenModal}
                    logout={logout}
                    openmodal={openmodal}
                    handleCloseModal={handleCloseModal}
                    updateProfile={updateProfile}
                    handleChange={handleProfileChange}
                />
                <main style={{ marginTop: "20px", marginBottom: "20px" }}>
                    <Paper elevation={2} style={{ padding: "20px" }}>
                        <Stepper activeStep={activeStep - 1} alternativeLabel>
                            {
                                steplabel.map((label) =>
                                    <Step key={label}>
                                        <StepLabel>{label}</StepLabel>
                                    </Step>
                                )
                            }
                        </Stepper>
                        <Fragment>
                            <div style={{ margin: "0px 30px 30px 30px" }}>
                                {getStepContent(activeStep)}
                            </div>
                            <div>
                                <Fragment>
                                    <Button
                                        variant="contained"
                                        disabled={activeStep === 1}
                                        onClick={previousStep}
                                        style={{ marginRight: "10px" }}
                                    >Previous</Button>
                                    <Button variant="contained" color="primary" hidden={activeStep === 3} onClick={nextStep}>
                                        {activeStep === steplabel.length ? 'Start Quiz' : 'Next'}</Button>
                                    {/* <Button variant="contained" color="primary" onClick={nextStep}>
                                        {activeStep === steplabel.length ? 'Start Quiz' : 'Next'}</Button> */}
                                </Fragment>
                            </div>
                        </Fragment>
                    </Paper>
                    <Footer />
                </main>
            </Container>
            {
                Loading ? <div id="preloader"></div> : ""
            }
        </Fragment>
    )
});

export default Dashboard
