import React, { Fragment, useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { Typography, Grid, Radio, RadioGroup, FormControlLabel, FormControl, Button } from '@material-ui/core';
import Questions from '../Questions';
import Constants from '../../Constants'
import useProfile from '../../Hooks/useProfile'
import Quiz from '../Quiz';
import { toast } from 'react-toastify'

const QuizCompo = React.memo(() => {

    const history = useHistory();

    const [personalityTest, setPersonalityTest] = useState([]);

    const { loader, showLoader, hideLoader } = useProfile();

    const [personalityQ, setPersonalityQ] = useState({
        question1: "", question2: "", question3: "", question4: "", question5: "", question6: "", question7: "",
        question8: "", question9: "", question10: "", question11: "", question12: "", question13: "", question14: "",
        question15: "", question16: "", question17: "", question18: "", question19: "", question20: "",
        question21: "", question22: "", question23: "", question24: "", question25: "", question26: "", question27: "",
        question28: "", question29: "", question30: "", question31: "", question32: "", question33: "", question34: "",
        question35: "", question36: "", question37: "", question38: "", question39: "", question40: "",
        question41: "", question42: "", question43: "", question44: "", question45: "", question46: "", question47: "",
        question48: "", question49: "", question50: "", question51: "", question52: "", question53: "", question54: "",
        question55: "", question56: "", question57: "", question58: "", question59: "", question60: "",
        question61: "", question62: "", question63: "", question64: "", question65: "", question66: "",
    });

    // HandleChange Input7
    const handleChange = (e) => {
        setPersonalityQ((prevProps) => ({
            ...prevProps,
            [e.target.name]: e.target.value
        }));
    }

    const submitHandler = async () => {
        showLoader();
        const response = await fetch(Constants.url + 'questions/personality', {
            method: 'POST',
            headers: {
                'Content-type': 'application/json',
                'Authorization': `Bearer ${JSON.parse(localStorage.getItem('token'))}`
            },
            body: JSON.stringify(personalityQ)
        });
        const result = await response.json();
        if (result.status === 'success') {
            history.push('/end')
            sessionStorage.setItem('Qpoints',JSON.stringify({q1p1:'1',q1p2:'1',q2:'1',q3:'1',q4:'1',q5:'1',q6:'1',q7:'1',pq:'1'}));
            hideLoader()
            toast.success(result.message);
        }
        else {
            hideLoader()
            toast.error(result.message);
            return false;
        }
    }

    useEffect(() => {
        setPersonalityTest(Questions['personalityTest'])
    },[])

    return (
        <Fragment>
            <div>
                <Typography variant="h6" color="inherit" >Personality Test</Typography>
                <Typography variant="h6" color="inherit" style={{ margin: "20px auto", width: "70%" }}>Given below are 66 sentences describing various activities. Each of these have to be marked as either "L" if you Like it or "D" if you don't like it/ dislike it</Typography>
                <Grid container direction="row" spacing={2} justify="center" alignItems="center" className="Questions row_base7">
                    {
                        personalityTest.map((item, index) => (
                            <Fragment key={index} >
                                <Grid item xs={12} sm={6} md={6} lg={4} style={{ padding: "8px 14px" }}>
                                    <p className="mb-0"><span className="me-3 dot">{item.id}.</span>{item.question}</p>
                                    <FormControl component="fieldset">
                                        <RadioGroup aria-label={`question${item.id}`} name={`question${item.id}`} value={personalityQ.question} onChange={handleChange}>
                                            <FormControlLabel value='1' control={<Radio />} label='L' />
                                            <FormControlLabel value='0' control={<Radio />} label='D' />
                                        </RadioGroup>
                                    </FormControl>
                                </Grid>
                            </Fragment>
                        ))
                    }
                </Grid>
            </div>
            <div>
                <Fragment>
                    <Button variant="contained" color="primary" onClick={submitHandler}>
                        Next</Button>
                </Fragment>
            </div>
            {loader}
        </Fragment>
    )
});

const PersonalityTest = React.memo(() => {

    return (
        <Quiz QuizCompo={QuizCompo} />
    )
});

export default PersonalityTest
