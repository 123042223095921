import React, { Fragment, useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { Typography, Grid, Radio, RadioGroup, FormControlLabel, FormControl, Button } from '@material-ui/core';
import Questions from '../Questions';
import Timer from '../Timer';
import Constants from '../../Constants'
import useProfile from '../../Hooks/useProfile'
import Quiz from '../Quiz';
import { toast } from 'react-toastify'

const QuizCompo = React.memo(() => {

    const history = useHistory();

    const [question7a, setQuestion7a] = useState([]);
    const [question7b, setQuestion7b] = useState([]);
    const [disableQuestion, setDisableQuestion] = useState(false);

    const { loader, showLoader, hideLoader } = useProfile();

    // Set Answer7 State
    const [answer7, setAnswer7] = useState({
        question1: "", question2: "", question3: "", question4: "", question5: "", question6: "", question7: "",
        question8: "", question9: "", question10: "", question11: "", question12: "", question13: "", question14: "",
        question15: "", question16: "", question17: "", question18: "", question19: "", question20: "",
        question21: "", question22: "", question23: "", question24: "", question25: "", question26: "", question27: "",
        question28: "", question29: "", question30: "", question31: "", question32: "", question33: "", question34: "",
        question35: "", question36: "", question37: "", question38: "", question39: "", question40: "",
        question41: "", question42: "", question43: "", question44: "", question45: "", question46: "", question47: "",
        question48: "", question49: "", question50: "", question51: "", question52: "", question53: "", question54: "",
        question55: "", question56: "", question57: "", question58: "", question59: "", question60: "",
        question61: "", question62: "", question63: "", question64: "", question65: "", question66: "", question67: "",
        question68: "", question69: "", question70: "", question71: "", question72: "",
        ans1: "d", ans2: "s", ans3: "d", ans4: "s", ans5: "s", ans6: "d", ans7: "s",
        ans8: "d", ans9: "d", ans10: "s", ans11: "s", ans12: "s", ans13: "d", ans14: "d",
        ans15: "s", ans16: "d", ans17: "d", ans18: "s", ans19: "s", ans20: "s",
        ans21: "s", ans22: "d", ans23: "s", ans24: "d", ans25: "s", ans26: "d", ans27: "s",
        ans28: "d", ans29: "d", ans30: "s", ans31: "s", ans32: "s", ans33: "s", ans34: "d",
        ans35: "d", ans36: "s", ans37: "d", ans38: "s", ans39: "s", ans40: "d",
        ans41: "s", ans42: "d", ans43: "s", ans44: "d", ans45: "d", ans46: "d", ans47: "s",
        ans48: "s", ans49: "d", ans50: "s", ans51: "d", ans52: "s", ans53: "s", ans54: "s",
        ans55: "d", ans56: "s", ans57: "s", ans58: "s", ans59: "d", ans60: "d",
        ans61: "d", ans62: "s", ans63: "d", ans64: "d", ans65: "s", ans66: "d", ans67: "s",
        ans68: "d", ans69: "s", ans70: "s", ans71: "s", ans72: "d",
    });

    // HandleChange Input7
    const handleChange = (e) => {
        setAnswer7((prevProps) => ({
            ...prevProps,
            [e.target.name]: e.target.value
        }));
    }

    const submitHandler = async () => {
        showLoader();
        const response = await fetch(Constants.url + 'questions/question7', {
            method: 'POST',
            headers: {
                'Content-type': 'application/json',
                'Authorization': `Bearer ${JSON.parse(localStorage.getItem('token'))}`
            },
            body: JSON.stringify(answer7)
        });
        const result = await response.json();
        if (result.status === 'success') {
            history.push('/dbda_ra')
            sessionStorage.setItem('Qpoints',JSON.stringify({q1p1:'1',q1p2:'1',q2:'1',q3:'1',q4:'1',q5:'1',q6:'1',q7:'0',pq:'0'}));
            hideLoader()
            toast.success(result.message);
        }
        else {
            hideLoader()
            toast.error(result.message);
            return false;
        }
    }

    useEffect(() => {
        setQuestion7a(Questions['DBDA-CLA'])
        setQuestion7b(Questions['DBDA-CLB'])
    },[])

    return (
        <Fragment>
            <div>
                <Typography variant="h6" color="inherit" >CL-Clerical ability</Typography>
                <Timer initialMinute="5" initialSeconds="1" setDisableQuestion={setDisableQuestion} hideonCheck="1"></Timer>
                <Grid container direction="row" spacing={2} justify="center" alignItems="center" className="Questions row_base7">
                    {
                        question7a.map((item, index) => (
                            <Fragment key={index} >
                                <Grid item xs={12} sm={6} md={4} lg={3}>
                                    <p className="mb-0"><span className="me-3 dot">{item.id}.</span>{item.a}&nbsp;&nbsp;&nbsp;{item.b}</p>
                                    <FormControl component="fieldset">
                                        <RadioGroup aria-label={`question${item.id}`} name={`question${item.id}`} value={answer7.question} onChange={handleChange}>
                                            <FormControlLabel value='s' control={<Radio />} label='S' disabled={disableQuestion} />
                                            <FormControlLabel value='d' control={<Radio />} label='D' disabled={disableQuestion} />
                                        </RadioGroup>
                                    </FormControl>
                                </Grid>
                            </Fragment>
                        ))
                    }
                    {
                        question7b.map((item, index) => (
                            <Fragment key={index} >
                                <Grid item xs={12} sm={6} md={4} lg={3}>
                                    <p className="mb-0"><span className="me-3 dot">{item.id}.</span>{item.a}&nbsp;&nbsp;&nbsp;{item.b}</p>
                                    <FormControl component="fieldset">
                                        <RadioGroup aria-label={`question${item.id}`} name={`question${item.id}`} value={answer7.question} onChange={handleChange}>
                                            <FormControlLabel value='s' control={<Radio />} label='S' disabled={disableQuestion} />
                                            <FormControlLabel value='d' control={<Radio />} label='D' disabled={disableQuestion} />
                                        </RadioGroup>
                                    </FormControl>
                                </Grid>
                            </Fragment>
                        ))
                    }
                </Grid>
            </div>
            <div>
                <Fragment>
                    <Button variant="contained" color="primary" onClick={submitHandler}>
                        Next</Button>
                </Fragment>
            </div>
            {loader}
        </Fragment>
    )
});

const Question7 = React.memo(() => {

    return (
        <Quiz QuizCompo={QuizCompo} />
    )
});


export default Question7
