import { React, Fragment,useEffect } from 'react';
import { useHistory } from 'react-router-dom'
import useFetch from '../Hooks/useFetch';
import Constants from '../Constants';
import Common from './Common';

const Mytext = () => {
     
    const history = useHistory(); 
    
    const { data: payCheck,Loading } = useFetch(Constants.url + 'questions/attemp_check/payments');
    const { data: userDetailData } = useFetch(Constants.url + 'auth/getUserDetail');
  

    useEffect(() => { 
        if(userDetailData && userDetailData.status === 'success') {
            var discount = userDetailData.data.discount;
            if(payCheck.check === '0' && discount !== '0') {
                history.push('/');
            }
        }
        
         // eslint-disable-next-line
    }, [history,payCheck])

    return (
        <Fragment>
            <div className="text-center">                
                <h4>Take aptitude and interest test <p style={{display:"inline",color:"#46A3DC"}}>Free</p></h4>
                <p>1. Finish the quiz in 1 attempt and do not press back button when the quiz is in progress</p>
                <p>2. Do not Reload the page when submit a particular question</p>
            </div>
            {
                Loading ? <div id="preloader"></div> : ""
            }
        </Fragment>
    )
}
const Quizselection = () => {

    return (
        <Fragment>
            <Common Text={Mytext} />
        </Fragment>
    )
};

export default Quizselection;