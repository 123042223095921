import React, { Fragment, useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { Typography, Grid, Radio, RadioGroup, FormControlLabel, FormControl, Button } from '@material-ui/core';
import Questions from '../Questions';
import Timer from '../Timer';
import Constants from '../../Constants'
import useProfile from '../../Hooks/useProfile'
import Quiz from '../Quiz';
import { toast } from 'react-toastify'

const QuizCompo = React.memo(() => {

    const history = useHistory();

    const [question4, setQuestion4] = useState([]);
    const [disableQuestion, setDisableQuestion] = useState(false);

    const { loader, showLoader, hideLoader } = useProfile();

    // Set Answer4 State
    const [answer4, setAnswer4] = useState({
        question1: "", question2: "", question3: "", question4: "", question5: "", question6: "", question7: "",
        question8: "", question9: "", question10: "", question11: "", question12: "", question13: "", question14: "",
        question15: "", question16: "", question17: "", question18: "", question19: "", question20: "",
        ans1: "b", ans2: "a", ans3: "c", ans4: "d", ans5: "c", ans6: "a", ans7: "a",
        ans8: "a", ans9: "b", ans10: "e", ans11: "d", ans12: "c", ans13: "e", ans14: "e",
        ans15: "b", ans16: "c", ans17: "d", ans18: "a", ans19: "d", ans20: "e",
    });

    // HandleChange Input4
    const handleChange = (e) => {
        setAnswer4((prevProps) => ({
            ...prevProps,
            [e.target.name]: e.target.value
        }));
    }

    const submitHandler = async () => {
        showLoader();
        const response = await fetch(Constants.url + 'questions/question4', {
            method: 'POST',
            headers: {
                'Content-type': 'application/json',
                'Authorization': `Bearer ${JSON.parse(localStorage.getItem('token'))}`
            },
            body: JSON.stringify(answer4)
        });
        const result = await response.json();
        if (result.status === 'success') {
            history.push('/dbda_sa')
            sessionStorage.setItem('Qpoints',JSON.stringify({q1p1:'1',q1p2:'1',q2:'1',q3:'1',q4:'0',q5:'0',q6:'0',q7:'0',pq:'0'}));
            hideLoader()
            toast.success(result.message);
        }
        else {
            hideLoader()
            toast.error(result.message);
            return false;
        }
    }

    
    useEffect(() => {
        setQuestion4(Questions['DBDA-CA'])
    },[])

    return (
        <Fragment>
            <div>
                <Typography variant="h6" color="inherit" >CA-Closure ability</Typography>
                <Timer initialMinute="5" initialSeconds="1" setDisableQuestion={setDisableQuestion} hideonCheck="0"></Timer>
                <Grid container direction="row" spacing={2} justify="center" alignItems="center" className="Questions row_base3">
                    {
                        question4.map((item, index) => (
                            <Fragment key={index} >
                                <Grid item xs={6} sm={4} md={3} lg={3}>
                                    <span className="me-3 dot">{item.id}.</span><img src={item.question} className="img-fluid" alt={item.question} />
                                </Grid>
                                <Grid item xs={6} sm={8} md={9} lg={9}>
                                    <FormControl component="fieldset">
                                        <RadioGroup aria-label={`question${item.id}`} name={`question${item.id}`} value={answer4.question} onChange={handleChange}>
                                            <FormControlLabel value='a' control={<Radio />} label={item.a} disabled={disableQuestion} />
                                            <FormControlLabel value='b' control={<Radio />} label={item.b} disabled={disableQuestion} />
                                            <FormControlLabel value='c' control={<Radio />} label={item.c} disabled={disableQuestion} />
                                            <FormControlLabel value='d' control={<Radio />} label={item.d} disabled={disableQuestion} />
                                            <FormControlLabel value='e' control={<Radio />} label={item.e} disabled={disableQuestion} />
                                        </RadioGroup>
                                    </FormControl>
                                </Grid>
                                <span className="border"></span>
                            </Fragment>
                        ))
                    }
                </Grid>
            </div>
            <div>
                <Fragment>
                    <Button variant="contained" color="primary" onClick={submitHandler}>
                        Next</Button>
                </Fragment>
            </div>
            {loader}
        </Fragment>
    )
});

const Question4 = React.memo(() => {

    return (
        <Quiz QuizCompo={QuizCompo}/>
    )
});

export default Question4
