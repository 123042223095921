import React, { Fragment,useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import useFetch from '../Hooks/useFetch';
import Constants from '../Constants';
import { Grid, Typography } from '@material-ui/core';
import ReactPlayer from 'react-player';
import { CssBaseline, Container, Paper, Button } from '@material-ui/core';
import useProfile from '../Hooks/useProfile'
import Topbar from '../Topbar';
import Footer from '../Footer';

const Videos = React.memo(({ url, title, onPress }) => {

    const history = useHistory();
    
    const { data: userDetailData } = useFetch(Constants.url + 'auth/getUserDetail');
    const { data: payCheck,Loading } = useFetch(Constants.url + 'questions/attemp_check/payments');    

    useEffect(() => {
        if(userDetailData && userDetailData.status === 'success') {
            var discount = userDetailData.data.discount;
            if(title !== 'End') {
                if(payCheck.check === '0' && discount !== '0') {
                    history.push('/');
                }
            }
        }
    }, [title,userDetailData,history,payCheck])

    const { userData,  logout, handleProfileChange, updateProfile,
        anchorEl, open, handleMenu, handleClose,
        openmodal, handleOpenModal, handleCloseModal } = useProfile();

    return (
        <Fragment>
            <CssBaseline />
            <Container maxWidth="lg">
                <Topbar
                    userData={userData}
                    handleMenu={handleMenu}
                    anchorEl={anchorEl}
                    open={open}
                    handleClose={handleClose}
                    handleOpenModal={handleOpenModal}
                    logout={logout}
                    openmodal={openmodal}
                    handleCloseModal={handleCloseModal}
                    updateProfile={updateProfile}
                    handleChange={handleProfileChange}
                />
                <main style={{ marginTop: "20px", marginBottom: "20px" }}>
                    <Paper elevation={2} style={{ padding: "20px 0px" }}>
                        <Fragment>
                            <div>
                                <Typography variant="h4" color="inherit" >{title}</Typography>
                                <Grid container direction="row" spacing={2} justify="center" alignItems="center" className="Questions">
                                    <ReactPlayer url={url} controls width="680px" height="400px" onEnded={() => {
                                        if (title === 'End') {
                                            window.location = "https://careers4me.in/console/"
                                            logout();
                                        }
                                    }} />
                                </Grid>
                            </div>
                            <div>
                                <Fragment>
                                    <Button variant="contained" color="primary" onClick={() => {
                                        onPress(); 
                                        if (title === 'End') {
                                            logout()
                                        }
                                    }}>
                                        {title === 'End' ? 'Finish' : 'Next'}</Button>
                                </Fragment>
                            </div>
                        </Fragment>
                    </Paper>
                    <Footer />
                </main>
            </Container>
            <Fragment>
                {
                    Loading ? <div id="preloader"></div> : ""
                }
            </Fragment>
        </Fragment>
    )
});

export default Videos
