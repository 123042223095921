import React, { Fragment,useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import useFetch from '../Hooks/useFetch';
import Constants from '../Constants';
import useProfile from '../Hooks/useProfile'
import { CssBaseline, Container, Paper } from '@material-ui/core';
import Topbar from '../Topbar';
import Footer from '../Footer';
import { toast } from 'react-toastify';

const Quiz = React.memo(({ QuizCompo }) => {
    
    const history = useHistory(); 

    const { data: userDetailData } = useFetch(Constants.url + 'auth/getUserDetail');
    const { data: payCheck,Loading } = useFetch(Constants.url + 'questions/attemp_check/payments');   

    const checkValidQ = JSON.parse(sessionStorage.getItem('Qpoints'));

    useEffect(() => {
        if(userDetailData && userDetailData.status === 'success') {
            var discount = userDetailData.data.discount;
            if(payCheck.check === '0'  && discount !== '0') {
                history.push('/');
            }
            if(sessionStorage.hasOwnProperty("Qpoints")) {
                if(checkValidQ.q1p1 === '0' && checkValidQ.q1p2 === '0' && checkValidQ.q2 === '0' && checkValidQ.q3 === '0' &&
                    checkValidQ.q4 === '0' && checkValidQ.q5 === '0' && checkValidQ.q6 === '0' && checkValidQ.q7 === '0' && checkValidQ.pq === '0') {
                    history.push('/question1');
                }
                else if(checkValidQ.q1p1 === '1' && checkValidQ.q1p2 === '0' && checkValidQ.q2 === '0' && checkValidQ.q3 === '0' &&
                    checkValidQ.q4 === '0' && checkValidQ.q5 === '0' && checkValidQ.q6 === '0' && checkValidQ.q7 === '0' && checkValidQ.pq === '0') {
                    history.push('/question2');
                }
                else if(checkValidQ.q1p1 === '1' && checkValidQ.q1p2 === '1' && checkValidQ.q2 === '0' && checkValidQ.q3 === '0' &&
                    checkValidQ.q4 === '0' && checkValidQ.q5 === '0' && checkValidQ.q6 === '0' && checkValidQ.q7 === '0' && checkValidQ.pq === '0') {
                    history.push('/question3');
                }
                else if(checkValidQ.q1p1 === '1' && checkValidQ.q1p2 === '1' && checkValidQ.q2 === '1' && checkValidQ.q3 === '0' &&
                    checkValidQ.q4 === '0' && checkValidQ.q5 === '0' && checkValidQ.q6 === '0' && checkValidQ.q7 === '0' && checkValidQ.pq === '0') {
                    history.push('/question4');
                }
                else if(checkValidQ.q1p1 === '1' && checkValidQ.q1p2 === '1' && checkValidQ.q2 === '1' && checkValidQ.q3 === '1' &&
                    checkValidQ.q4 === '0' && checkValidQ.q5 === '0' && checkValidQ.q6 === '0' && checkValidQ.q7 === '0' && checkValidQ.pq === '0') {
                    history.push('/question5');
                }
                else if(checkValidQ.q1p1 === '1' && checkValidQ.q1p2 === '1' && checkValidQ.q2 === '1' && checkValidQ.q3 === '1' &&
                    checkValidQ.q4 === '1' && checkValidQ.q5 === '0' && checkValidQ.q6 === '0' && checkValidQ.q7 === '0' && checkValidQ.pq === '0') {
                    history.push('/question6');
                }
                else if(checkValidQ.q1p1 === '1' && checkValidQ.q1p2 === '1' && checkValidQ.q2 === '1' && checkValidQ.q3 === '1' &&
                    checkValidQ.q4 === '1' && checkValidQ.q5 === '1' && checkValidQ.q6 === '0' && checkValidQ.q7 === '0' && checkValidQ.pq === '0') {
                    history.push('/question7');
                }
                else if(checkValidQ.q1p1 === '1' && checkValidQ.q1p2 === '1' && checkValidQ.q2 === '1' && checkValidQ.q3 === '1' &&
                    checkValidQ.q4 === '1' && checkValidQ.q5 === '1' && checkValidQ.q6 === '1' && checkValidQ.q7 === '0' && checkValidQ.pq === '0') {
                    history.push('/question8');
                }
                else if(checkValidQ.q1p1 === '1' && checkValidQ.q1p2 === '1' && checkValidQ.q2 === '1' && checkValidQ.q3 === '1' &&
                    checkValidQ.q4 === '1' && checkValidQ.q5 === '1' && checkValidQ.q6 === '1' && checkValidQ.q7 === '1' && checkValidQ.pq === '0') {
                    history.push('/personalitytest');
                }
                else if(checkValidQ.q1p1 === '1' && checkValidQ.q1p2 === '1' && checkValidQ.q2 === '1' && checkValidQ.q3 === '1' &&
                    checkValidQ.q4 === '1' && checkValidQ.q5 === '1' && checkValidQ.q6 === '1' && checkValidQ.q7 === '1' && checkValidQ.pq === '1') {
                    history.push('/end');
                }
                else {
                    history.push('/intro');
                }
            }
            else {
                history.push('/intro');
                if(window.location.pathname !== '/login') {
                    toast.error('Session Invalid Please referesh the page');
                }
            }
        }
    }, [userDetailData,history,payCheck,checkValidQ])

    const { userData, logout, handleProfileChange, updateProfile,
        anchorEl, open, handleMenu, handleClose,
        openmodal, handleOpenModal, handleCloseModal } = useProfile();

    return (
        <Fragment>
            <CssBaseline />
            <Container maxWidth="lg">
                <Topbar
                    userData={userData}
                    handleMenu={handleMenu}
                    anchorEl={anchorEl}
                    open={open}
                    handleClose={handleClose}
                    handleOpenModal={handleOpenModal}
                    logout={logout}
                    openmodal={openmodal}
                    handleCloseModal={handleCloseModal}
                    updateProfile={updateProfile}
                    handleChange={handleProfileChange}
                />
                <main style={{ marginTop: "20px", marginBottom: "20px" }}>
                    <Paper elevation={2} style={{ padding: "20px 0px" }}>
                        <Container>
                            {<QuizCompo />}
                        </Container>
                    </Paper>
                    <Footer />
                </main>
            </Container>
            <Fragment>
                {
                    Loading ? <div id="preloader"></div> : ""
                }
            </Fragment>
        </Fragment>
    )
});

export default Quiz
