import { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import useLoader from '../Hooks/useLoader'
import Constants from '../Constants'
import { toast } from 'react-toastify'

const useProfile = () => {

    const history = useHistory();
    const [loader, showLoader, hideLoader] = useLoader();
    const [userData, setUserData] = useState('')

    useEffect(() => {
        setUserData(JSON.parse(localStorage.getItem('userData')));
        // eslint-disable-next-line
    }, [])

    //AppBar Handles
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);

    const handleMenu = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    // Modal Handles
    const [openmodal, setOpenModal] = useState(false);

    const handleOpenModal = () => {
        setOpenModal(true);
    };

    const handleCloseModal = () => {
        setOpenModal(false);
    }

    //-------Api Calls-------

    //Logout User
    const logout = async () => {
        showLoader();
        const response = await fetch(Constants.url + 'auth/logout', {
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${JSON.parse(localStorage.getItem('token'))}`
            }
        });
        const result = await response.json();
        if (result.status === 'success') {
            hideLoader();
            toast.success(result.message);
            // sessionStorage.removeItem('price');
            // sessionStorage.removeItem('Qpoints');
            localStorage.clear();
            sessionStorage.clear();
            history.push('/login');
        }
    }

    const [profilestate, setProfileState] = useState({
        name: "",
        password: "",
    });

    const handleProfileChange = (e) => {
        setProfileState({
            ...profilestate,
            [e.target.name]: e.target.value
        });
    }

    const updateProfile = async (e) => {
        e.preventDefault();

        if (!profilestate.name && !userData.name) {
            toast.error('All Fields Are Required')
        }
        else {
            showLoader();
            const profiledata = {
                name: profilestate.name ? profilestate.name : userData.name,
                email: userData.email,
                password: profilestate.password
            }
            const response = await fetch(Constants.url + 'auth/edit', {
                method: 'POST',
                headers: {
                    'Content-type': 'application/json',
                    'Authorization': `Bearer ${JSON.parse(localStorage.getItem('token'))}`
                },
                body: JSON.stringify(profiledata)
            });
            const result = await response.json();
            if (result.status === 'success') {
                toast.success(result.message)
                setOpenModal(false);
                const getLocalValues = JSON.parse(localStorage.getItem('userData'))
                getLocalValues.name = profilestate.name ? profilestate.name : userData.name;
                localStorage.setItem('userData', JSON.stringify(getLocalValues))
                setUserData(getLocalValues)
                hideLoader();
            }
            else {
                hideLoader();
                toast.error(result.message)
            }
        }

    }
    //-------End Api Calls-------

    return {
        userData, loader, showLoader,
        hideLoader, logout, handleProfileChange, updateProfile,
        anchorEl, open, handleMenu, handleClose,
        openmodal, handleOpenModal, handleCloseModal
    }
}

export default useProfile
