const questions = {
    'DBDA-VA PART-I': [
        {
            id: 1,
            question: 'FAST',
            a: 'a. old',
            b: 'b. rapid',
            c: 'c. slow',
            d: 'd. early',
            e: 'e. late',
            ans: 'b'
        },
        {
            id: 2,
            question: 'DECEIVE',
            a: 'a. blunder',
            b: 'b. obtain',
            c: 'c. conceal',
            d: 'd. mislead',
            e: 'e. disclose',
            ans: 'd'
        },
        {
            id: 3,
            question: 'EXCESS',
            a: 'a. waste',
            b: 'b. departure',
            c: 'c. surplus',
            d: 'd. tax',
            e: 'e. approach',
            ans: 'c'
        },
        {
            id: 4,
            question: 'BELIEVABLE',
            a: 'a. admirable',
            b: 'b. real',
            c: 'c. personable',
            d: 'd. unlikely',
            e: 'e. credible',
            ans: 'e'
        },
        {
            id: 5,
            question: 'CONTEMPLATE',
            a: 'a. heal',
            b: 'b. advance',
            c: 'c. meditate',
            d: 'd. rest',
            e: 'e. worry',
            ans: 'c'
        },
        {
            id: 6,
            question: 'AMLABLE',
            a: 'a. friendly',
            b: 'b. humorous',
            c: 'c. healthy',
            d: 'd. convincing',
            e: 'e. polished',
            ans: 'a'
        },
        {
            id: 7,
            question: 'TURMOIL',
            a: 'a. circular',
            b: 'b. turbulent',
            c: 'c. calm',
            d: 'd. spinning',
            e: 'e. air-borne',
            ans: 'b'
        },
        {
            id: 8,
            question: 'DECEPTIVE',
            a: 'a. illogical',
            b: 'b. illusory',
            c: 'c. magical',
            d: 'd. visible',
            e: 'e. clear',
            ans: 'b'
        },
        {
            id: 9,
            question: 'WHIMSICAL',
            a: 'a. unlike',
            b: 'b. musical',
            c: 'c. dancing',
            d: 'd. unpredictable',
            e: 'e. equatorial',
            ans: 'd'
        },
        {
            id: 10,
            question: 'APATHETIC',
            a: 'a. ignorant',
            b: 'b. indifferent',
            c: 'c. pitiful',
            d: 'd. concerned',
            e: 'e. clever',
            ans: 'b'
        },
        {
            id: 11,
            question: 'ARDUOUS',
            a: 'a. repulsive',
            b: 'b. loving',
            c: 'c. easy',
            d: 'd. interesting',
            e: 'e. strenous',
            ans: 'e'
        },
        {
            id: 12,
            question: 'PLACATE',
            a: 'a. cover',
            b: 'b. beautify',
            c: 'c. arouse',
            d: 'd. plasticize',
            e: 'e. appease',
            ans: 'e'
        },
        {
            id: 13,
            question: 'CLANDESTINE',
            a: 'a. furtive',
            b: 'b. safe',
            c: 'c. tribal',
            d: 'd. open',
            e: 'e. healthful',
            ans: 'a'
        },
        {
            id: 14,
            question: 'VINDICATE',
            a: 'a. deny',
            b: 'b. state',
            c: 'c. defend',
            d: 'd. persecute',
            e: 'e. accuse',
            ans: 'd'
        },
        {
            id: 15,
            question: 'INCULCATE',
            a: 'a. grow',
            b: 'b. inquire',
            c: 'c. instill',
            d: 'd. compute',
            e: 'e. acquire',
            ans: 'c'
        },
    ],
    'DBDA-VA PART-II': [
        {
            id: 16,
            question: 'STRIKE WHILE THE IRON IS HOT',
            a: 'a. Take things as you find them',
            b: 'b. Hot love is soon cold',
            c: 'c. Make hay while the sun shines',
            d: 'd. First think and then speak',
            e: 'e. Look before you leap',
            ans: 'c'
        },
        {
            id: 17,
            question: 'IT NEVER RAINS BUT IT POURS',
            a: 'a. cloudy mornings turn to clear evenings',
            b: 'b. misfortunes never come one at a time',
            c: 'c. easy come, easy go',
            d: 'd. the least predictable things in life is the weather',
            e: 'e. every cloud has a silver lining',
            ans: 'b'
        },
        {
            id: 18,
            question: 'LET SLEEPING DOGS LIE',
            a: 'a. as you make your bed, so you must lie on it',
            b: 'b. do not keep a dog and bark yourself',
            c: 'c. there will be sleeping enough in the grave',
            d: 'd. never look for trouble; let trouble look for you',
            e: 'e. an old dog does not bark for nothing',
            ans: 'd'
        },
        {
            id: 19,
            question: 'THERE IS NO VENOM LIKE THAT OF THE TONGUE',
            a: 'a. the tongue of an idle person is never idle',
            b: 'b. talking pays no toll',
            c: 'c. few words are the best',
            d: 'd. words cut more than swords',
            e: 'e. bad news travel fast',
            ans: 'd'
        },
        {
            id: 20,
            question: 'IT IS ALWAYS DARKEST BEFORE THE DAWN',
            a: 'a. the longest day means the shortest night',
            b: 'b. what is done by night appears by day',
            c: 'c. he who runs in the dark may well stumble',
            d: 'd. he who groupes in the dark finds what he would not',
            e: 'e. when things are at the worst they will improve',
            ans: 'e'
        },
        {
            id: 21,
            question: 'ALL THAT GLITERS IS NOT GOLD',
            a: 'a. don’t judge a book by its cover',
            b: 'b. all men can’t be masters',
            c: 'c. gold dust blinds all eyes',
            d: 'd. money is the root of all evil',
            e: 'e. riches alone will not make a man happy',
            ans: 'a'
        },
        {
            id: 22,
            question: 'TOO MANY COOKS SPOIL THE BROTH',
            a: 'a. too much praise is a burden',
            b: 'b. too much consulting confounds',
            c: 'c. truth needs not many words',
            d: 'd. actions speak louder than words',
            e: 'e. when needs is highest, help is nighest',
            ans: 'b'
        },
        {
            id: 23,
            question: 'A STITCH IN TIME SAVES NINE',
            a: 'a. it is never too late to mend',
            b: 'b. time curse all things',
            c: 'c. prevention is better than cure',
            d: 'd. take time while time is, for time will away',
            e: 'e. it is not use crying over spilled milk',
            ans: 'c'
        },
        {
            id: 24,
            question: 'LITTLE STROKES FELL GREAT OAKS',
            a: 'a. step after step the ladder is ascended',
            b: 'b. great strokes make not sweet music ',
            c: 'c. tall oaks from little acorns grow',
            d: 'd. oaks may fall when reeds stand the storm',
            e: 'e. little things please little minds',
            ans: 'a'
        }
    ],
    'DBDA-NA': [
        {
            id: 1,
            question: 'Add 41+57+88+34',
            a: 'a. 230',
            b: 'b. 218',
            c: 'c. 200',
            d: 'd. 220',
            e: 'e. 219',
            ans: 'd'
        },
        {
            id: 2,
            question: 'Subtract  967-435',
            a: 'a. 552',
            b: 'b. 532',
            c: 'c. 522',
            d: 'd. 531',
            e: 'e. 523',
            ans: 'b'
        },
        {
            id: 3,
            question: 'Multiply  974 x 3',
            a: 'a. 2822',
            b: 'b. 2582',
            c: 'c. 2922',
            d: 'd. 2893',
            e: 'e. 2904',
            ans: 'c'
        },
        {
            id: 4,
            question: 'Divide  7√2226',
            a: 'a. 318',
            b: 'b. 316',
            c: 'c. 324',
            d: 'd. 326',
            e: 'e. 213',
            ans: 'a'
        },
        {
            id: 5,
            question: 'Multiply  253 x 20',
            a: 'a. 5050',
            b: 'b. 5060',
            c: 'c. 4760',
            d: 'd. 4750',
            e: 'e. 4050',
            ans: 'b'
        },
        {
            id: 6,
            question: 'Subtract  952 - 727',
            a: 'a. 224',
            b: 'b. 234',
            c: 'c. 235',
            d: 'd. 225',
            e: 'e. 325',
            ans: 'd'
        },
        {
            id: 7,
            question: 'Add 9(3/5) + 6(1/2)',
            a: 'a. 16(1/10)',
            b: 'b. 16(1/5)',
            c: 'c. 15(4/5)',
            d: 'd. 15(1/10)',
            e: 'e. 15(9/10)',
            ans: 'a'
        },
        {
            id: 8,
            question: 'Divide  4.8√7.20',
            a: 'a. 1.2',
            b: 'b. 15.0',
            c: 'c. 12.0',
            d: 'd. 0.12',
            e: 'e. 1.5',
            ans: 'e'
        },
        {
            id: 9,
            question: 'Multiply  4.52 x 5',
            a: 'a. 22.60',
            b: 'b. 22.50',
            c: 'c. 22.40',
            d: 'd. 22.26',
            e: 'e. 26.10',
            ans: 'a'
        },
        {
            id: 10,
            question: 'Divide  15√1050',
            a: 'a. 30',
            b: 'b. 32',
            c: 'c. 70',
            d: 'd. 72',
            e: 'e. 60',
            ans: 'c'
        },
        {
            id: 11,
            question: '2x(25 -19)/9',
            a: 'a. 2/3',
            b: 'b. 1(1/3)',
            c: 'c. 1(1/9)',
            d: 'd. 1(7/9)',
            e: 'e. 2(2/3)',
            ans: 'b'
        },
        {
            id: 12,
            question: 'Which one of the numbers below could replace X in both places in:\n4 + X = 6-X ?',
            a: 'a. 4',
            b: 'b. 3',
            c: 'c. 2',
            d: 'd. 1',
            e: 'e. -2',
            ans: 'd'
        },
        {
            id: 13,
            question: '7/8 x (7/10 -  3/5)',
            a: 'a. 39/40',
            b: 'b. 7/40',
            c: 'c. 1(11/80)',
            d: 'd. 2(11/40)',
            e: 'e. 7/80',
            ans: 'e'
        },
        {
            id: 14,
            question: '35(3/4) - 28(2/3)',
            a: 'a. 7(1/6)',
            b: 'b. 7(5/6)',
            c: 'c. 7(1/12)',
            d: 'd. 6(1/12)',
            e: 'e. 6(5/6)',
            ans: 'c'
        },
        {
            id: 15,
            question: '125% of 32',
            a: 'a. 6',
            b: 'b. 12',
            c: 'c. 36',
            d: 'd. 52',
            e: 'e. 40',
            ans: 'e'
        },
        {
            id: 16,
            question: '√196',
            a: 'a. 14',
            b: 'b. 16',
            c: 'c. 24',
            d: 'd. 86',
            e: 'e. 98',
            ans: 'a'
        },
        {
            id: 17,
            question: '5/7 of 245',
            a: 'a. 165',
            b: 'b. 175',
            c: 'c. 185',
            d: 'd. 207',
            e: 'e. 343',
            ans: 'b'
        },
        {
            id: 18,
            question: '(1/2 - 1/3) + 3/4',
            a: 'a. 1/3',
            b: 'b. 1/8',
            c: 'c. 2/9',
            d: 'd. 1 1/9',
            e: 'e. 5/8',
            ans: 'c'
        },
        {
            id: 19,
            question: '(1/2 + 1/3)²',
            a: 'a. 1/8',
            b: 'b. 81/256',
            c: 'c. 17/64',
            d: 'd. 25/64',
            e: 'e. 1 1/4',
            ans: 'c'
        },
        {
            id: 20,
            question: '(2/3 x 5/6) + 1/3',
            a: 'a. 1 5/6',
            b: 'b. 5/27',
            c: 'c. 1 1/9',
            d: 'd. 8/9',
            e: 'e. 7/9',
            ans: 'd'
        },
    ],
    'DBDA-CA': [
        {
            id: 1,
            question: 'https://careers4me.in/images/dbda-ca/1.png',
            a: 'a. rodba',
            b: 'b. dnbra',
            c: 'c. aibnr',
            d: 'd. radeb',
            e: 'e. bneas',
            ans: 'b'
        },
        {
            id: 2,
            question: 'https://careers4me.in/images/dbda-ca/2.png',
            a: 'a. sisleln',
            b: 'b. nselsu',
            c: 'c. rsipmes',
            d: 'd. drusens',
            e: 'e. ruseds',
            ans: 'a'
        },
        {
            id: 3,
            question: 'https://careers4me.in/images/dbda-ca/3.png',
            a: 'a. lowilw',
            b: 'b. rdweno',
            c: 'c. dwowni',
            d: 'd. nawred',
            e: 'e. nidrem',
            ans: 'c'
        },
        {
            id: 4,
            question: 'https://careers4me.in/images/dbda-ca/4.png',
            a: 'a. rgaon',
            b: 'b. gapna',
            c: 'c. lpano',
            d: 'd. ginaa',
            e: 'e. garin',
            ans: 'd'
        },
        {
            id: 5,
            question: 'https://careers4me.in/images/dbda-ca/5.png',
            a: 'a. rygzeran',
            b: 'b. ezcyrnai',
            c: 'c. izonrega',
            d: 'd. yezirdan',
            e: 'e. nyoneiza',
            ans: 'c'
        },
        {
            id: 6,
            question: 'https://careers4me.in/images/dbda-ca/6.png',
            a: 'a. onimr',
            b: 'b. rrimor',
            c: 'c. lermi',
            d: 'd. rmuro',
            e: 'e. ulrrc',
            ans: 'a'
        },
        {
            id: 7,
            question: 'https://careers4me.in/images/dbda-ca/7.png',
            a: 'a. rweat',
            b: 'b. twahe',
            c: 'c. lovra',
            d: 'd. twckei',
            e: 'e. berbru',
            ans: 'a'
        },
        {
            id: 8,
            question: 'https://careers4me.in/images/dbda-ca/8.png',
            a: 'a. eusscsc',
            b: 'b. aesrfcu',
            c: 'c. dscucee',
            d: 'd. sdsaces',
            e: 'e. pssrepus',
            ans: 'a'
        },
        {
            id: 9,
            question: 'https://careers4me.in/images/dbda-ca/9.png',
            a: 'a. yldiena',
            b: 'b. gdalein',
            c: 'c. lnoadgi',
            d: 'd. nlginae',
            e: 'e. ldidaene',
            ans: 'b'
        },
        {
            id: 10,
            question: 'https://careers4me.in/images/dbda-ca/10.png',
            a: 'a. irouf',
            b: 'b. rknfa',
            c: 'c. cflka',
            d: 'd. efrla',
            e: 'e. alnfk',
            ans: 'e'
        },
        {
            id: 11,
            question: 'https://careers4me.in/images/dbda-ca/11.png',
            a: 'a. leorfma',
            b: 'b. altnemni',
            c: 'c. aintmlne',
            d: 'd. letanirm',
            e: 'e. faelrimr',
            ans: 'd'
        },
        {
            id: 12,
            question: 'https://careers4me.in/images/dbda-ca/12.png',
            a: 'a. dernccee',
            b: 'b. condiunce',
            c: 'c. ndeucaei',
            d: 'd. enoduine',
            e: 'e. eodncnie',
            ans: 'c'
        },
        {
            id: 13,
            question: 'https://careers4me.in/images/dbda-ca/13.png',
            a: 'a. ljoyl',
            b: 'b. glaji',
            c: 'c. yegll',
            d: 'd. eyjil',
            e: 'e. lylej',
            ans: 'e'
        },
        {
            id: 14,
            question: 'https://careers4me.in/images/dbda-ca/14.png',
            a: 'a. hytrae',
            b: 'b. amgienn',
            c: 'c. meyinan',
            d: 'd. ylnmeai',
            e: 'e. lyehaht',
            ans: 'e'
        },
        {
            id: 15,
            question: 'https://careers4me.in/images/dbda-ca/15.png',
            a: 'a. trsepen',
            b: 'b. tpnesaa',
            c: 'c. patsran',
            d: 'd. psarnet',
            e: 'e. trnoasp',
            ans: 'b'
        },
        {
            id: 16,
            question: 'https://careers4me.in/images/dbda-ca/16.png',
            a: 'a. hcicn',
            b: 'b. issgh',
            c: 'c. nices',
            d: 'd. hnies',
            e: 'e. ncaih',
            ans: 'c'
        },
        {
            id: 17,
            question: 'https://careers4me.in/images/dbda-ca/17.png',
            a: 'a. cmlignba',
            b: 'b. abcorlig',
            c: 'c. grmlaigh',
            d: 'd. mbigagnl',
            e: 'e. lirggamn',
            ans: 'd'
        },
        {
            id: 18,
            question: 'https://careers4me.in/images/dbda-ca/18.png',
            a: 'a. maarce',
            b: 'b. rlnua',
            c: 'c. rtaem',
            d: 'd. yllauble',
            e: 'e. nocraa',
            ans: 'a'
        },
        {
            id: 19,
            question: 'https://careers4me.in/images/dbda-ca/19.png',
            a: 'a. ecrtcor',
            b: 'b. aocrtr',
            c: 'c. acmaer',
            d: 'd. tnoanc',
            e: 'e. fornca',
            ans: 'd'
        },
        {
            id: 20,
            question: 'https://careers4me.in/images/dbda-ca/20.png',
            a: 'a. nroait',
            b: 'b. ruatne',
            c: 'c. gtarin',
            d: 'd. tinono',
            e: 'e. ntiaon',
            ans: 'e'
        },
    ],
    'DBDA-SA': [
        {
            id: '1-6',
            question: 'https://careers4me.in/images/dbda-sa/1-6.png',
            a: 'https://careers4me.in/images/dbda-sa/1.png',
            b: 'https://careers4me.in/images/dbda-sa/2.png',
            c: 'https://careers4me.in/images/dbda-sa/3.png',
            d: 'https://careers4me.in/images/dbda-sa/4.png',
            e: 'https://careers4me.in/images/dbda-sa/5.png',
            f: 'https://careers4me.in/images/dbda-sa/6.png',
            l: '1',
            m: '2',
            n: '3',
            o: '4',
            p: '5',
            q: '6',
        },
        {
            id: '7-12',
            question: 'https://careers4me.in/images/dbda-sa/7-12.png',
            a: 'https://careers4me.in/images/dbda-sa/7.png',
            b: 'https://careers4me.in/images/dbda-sa/8.png',
            c: 'https://careers4me.in/images/dbda-sa/9.png',
            d: 'https://careers4me.in/images/dbda-sa/10.png',
            e: 'https://careers4me.in/images/dbda-sa/11.png',
            f: 'https://careers4me.in/images/dbda-sa/12.png',
            l: '7',
            m: '8',
            n: '9',
            o: '10',
            p: '11',
            q: '12',
        },
        {
            id: '13-18',
            question: 'https://careers4me.in/images/dbda-sa/13-18.png',
            a: 'https://careers4me.in/images/dbda-sa/13.png',
            b: 'https://careers4me.in/images/dbda-sa/14.png',
            c: 'https://careers4me.in/images/dbda-sa/15.png',
            d: 'https://careers4me.in/images/dbda-sa/16.png',
            e: 'https://careers4me.in/images/dbda-sa/17.png',
            f: 'https://careers4me.in/images/dbda-sa/18.png',
            l: '13',
            m: '14',
            n: '15',
            o: '16',
            p: '17',
            q: '18',
        },
        {
            id: '19-24',
            question: 'https://careers4me.in/images/dbda-sa/19-24.png',
            a: 'https://careers4me.in/images/dbda-sa/19.png',
            b: 'https://careers4me.in/images/dbda-sa/20.png',
            c: 'https://careers4me.in/images/dbda-sa/21.png',
            d: 'https://careers4me.in/images/dbda-sa/22.png',
            e: 'https://careers4me.in/images/dbda-sa/23.png',
            f: 'https://careers4me.in/images/dbda-sa/24.png',
            l: '19',
            m: '20',
            n: '21',
            o: '22',
            p: '23',
            q: '24',
        },
        {
            id: '25-30',
            question: 'https://careers4me.in/images/dbda-sa/25-30.png',
            a: 'https://careers4me.in/images/dbda-sa/25.png',
            b: 'https://careers4me.in/images/dbda-sa/26.png',
            c: 'https://careers4me.in/images/dbda-sa/27.png',
            d: 'https://careers4me.in/images/dbda-sa/28.png',
            e: 'https://careers4me.in/images/dbda-sa/29.png',
            f: 'https://careers4me.in/images/dbda-sa/30.png',
            l: '25',
            m: '26',
            n: '27',
            o: '28',
            p: '29',
            q: '30',
        },
        {
            id: '31-36',
            question: 'https://careers4me.in/images/dbda-sa/31-36.png',
            a: 'https://careers4me.in/images/dbda-sa/31.png',
            b: 'https://careers4me.in/images/dbda-sa/32.png',
            c: 'https://careers4me.in/images/dbda-sa/33.png',
            d: 'https://careers4me.in/images/dbda-sa/34.png',
            e: 'https://careers4me.in/images/dbda-sa/35.png',
            f: 'https://careers4me.in/images/dbda-sa/36.png',
            l: '31',
            m: '32',
            n: '33',
            o: '34',
            p: '35',
            q: '36',
        },
        {
            id: '37-42',
            question: 'https://careers4me.in/images/dbda-sa/37-42.png',
            a: 'https://careers4me.in/images/dbda-sa/37.png',
            b: 'https://careers4me.in/images/dbda-sa/38.png',
            c: 'https://careers4me.in/images/dbda-sa/39.png',
            d: 'https://careers4me.in/images/dbda-sa/40.png',
            e: 'https://careers4me.in/images/dbda-sa/41.png',
            f: 'https://careers4me.in/images/dbda-sa/42.png',
            l: '37',
            m: '38',
            n: '39',
            o: '40',
            p: '41',
            q: '42',
        },
        {
            id: '43-48',
            question: 'https://careers4me.in/images/dbda-sa/43-48.png',
            a: 'https://careers4me.in/images/dbda-sa/43.png',
            b: 'https://careers4me.in/images/dbda-sa/44.png',
            c: 'https://careers4me.in/images/dbda-sa/45.png',
            d: 'https://careers4me.in/images/dbda-sa/46.png',
            e: 'https://careers4me.in/images/dbda-sa/47.png',
            f: 'https://careers4me.in/images/dbda-sa/48.png',
            l: '43',
            m: '44',
            n: '45',
            o: '46',
            p: '47',
            q: '48',
        },
        {
            id: '49-54',
            question: 'https://careers4me.in/images/dbda-sa/49-54.png',
            a: 'https://careers4me.in/images/dbda-sa/49.png',
            b: 'https://careers4me.in/images/dbda-sa/50.png',
            c: 'https://careers4me.in/images/dbda-sa/51.png',
            d: 'https://careers4me.in/images/dbda-sa/52.png',
            e: 'https://careers4me.in/images/dbda-sa/53.png',
            f: 'https://careers4me.in/images/dbda-sa/54.png',
            l: '49',
            m: '50',
            n: '51',
            o: '52',
            p: '53',
            q: '54',
        },
        {
            id: '55-60',
            question: 'https://careers4me.in/images/dbda-sa/55-60.png',
            a: 'https://careers4me.in/images/dbda-sa/55.png',
            b: 'https://careers4me.in/images/dbda-sa/56.png',
            c: 'https://careers4me.in/images/dbda-sa/57.png',
            d: 'https://careers4me.in/images/dbda-sa/58.png',
            e: 'https://careers4me.in/images/dbda-sa/59.png',
            f: 'https://careers4me.in/images/dbda-sa/60.png',
            l: '55',
            m: '56',
            n: '57',
            o: '58',
            p: '59',
            q: '60',
        },
        {
            id: '61-66',
            question: 'https://careers4me.in/images/dbda-sa/61-66.png',
            a: 'https://careers4me.in/images/dbda-sa/61.png',
            b: 'https://careers4me.in/images/dbda-sa/62.png',
            c: 'https://careers4me.in/images/dbda-sa/63.png',
            d: 'https://careers4me.in/images/dbda-sa/64.png',
            e: 'https://careers4me.in/images/dbda-sa/65.png',
            f: 'https://careers4me.in/images/dbda-sa/66.png',
            l: '61',
            m: '62',
            n: '63',
            o: '64',
            p: '65',
            q: '66',
        },
        {
            id: '67-72',
            question: 'https://careers4me.in/images/dbda-sa/67-72.png',
            a: 'https://careers4me.in/images/dbda-sa/67.png',
            b: 'https://careers4me.in/images/dbda-sa/68.png',
            c: 'https://careers4me.in/images/dbda-sa/69.png',
            d: 'https://careers4me.in/images/dbda-sa/70.png',
            e: 'https://careers4me.in/images/dbda-sa/71.png',
            f: 'https://careers4me.in/images/dbda-sa/72.png',
            l: '67',
            m: '68',
            n: '69',
            o: '70',
            p: '71',
            q: '72',
        },
    ],
    'DBDA-MA': [
        {
            id: 1,
            image: ['https://careers4me.in/images/dbda-ma/1.png', '', ''],
            question: 'On what material would this chisel be used?',
            a: 'a. metal',
            b: 'b. cement',
            c: 'c. plastic',
            d: 'd. wood',
            e: 'e. glass',
            ans: 'b'
        },
        {
            id: 2,
            image: ['https://careers4me.in/images/dbda-ma/2.png', '', ''],
            question: 'If the gear on the left turns in th direction of the arrow, which way does the gear on the right turn?',
            a: 'a. A',
            b: 'b. B',
            c: 'c. C',
            d: 'd. alternately B and C',
            e: 'e. the gear does not turn',
            ans: 'a'
        },
        {
            id: 3,
            image: ['https://careers4me.in/images/dbda-ma/3.png', '', ''],
            question: 'What is this tool',
            a: 'a. drill',
            b: 'b. welding torch',
            c: 'c. paint sprayer',
            d: 'd. soldering gun',
            e: 'e. burning tool',
            ans: 'c'
        },
        {
            id: 4,
            image: ['', '', ''],
            question: 'A device that does the same thing as a fuse does is called a(n)',
            a: 'a. circuit breaker',
            b: 'b. resistor',
            c: 'c. relay',
            d: 'd. alternator',
            e: 'e. voltage regulator',
            ans: 'd'
        },
        {
            id: 5,
            image: [
                'https://careers4me.in/images/dbda-ma/5.1.png',
                'https://careers4me.in/images/dbda-ma/5.2.png',
                'https://careers4me.in/images/dbda-ma/5.3.png',
            ],
            question: 'In which of the following situations is the least force needed at the arrow,to balance the lever?',
            a: 'a. A',
            b: 'b. B',
            c: 'c. C',
            d: 'd. A and C',
            e: 'e. B and C',
            ans: 'c'
        },
        {
            id: 6,
            image: ['https://careers4me.in/images/dbda-ma/6.png', '', ''],
            question: 'Where should the highway be elevated or raised?',
            a: 'a. A',
            b: 'b. B',
            c: 'c. C',
            d: 'd. D',
            e: 'e. E',
            ans: 'a'
        },
        {
            id: 7,
            image: ['https://careers4me.in/images/dbda-ma/7.png', '', ''],
            question: 'Which of the shelves will support the greatest weight?',
            a: 'a. A',
            b: 'b. B',
            c: 'c. C',
            d: 'd. D',
            e: 'e. they will all support the same weight',
            ans: 'a'
        },
        {
            id: 8,
            image: ['https://careers4me.in/images/dbda-ma/8.png', '', ''],
            question: 'If Gear X is turning in the direction of the arrow, in what direction and how fast is Gear Y turning?',
            a: 'a. In direction A and the same speed as Gear X',
            b: 'b. In direction A and faster than Gear X',
            c: 'c. In direction B and slower than Gear X',
            d: 'd. In direction B and the same speed as Gear X',
            e: 'e. In direction B and faster than Gear X',
            ans: 'a'
        },
        {
            id: 9,
            image: ['', '', ''],
            question: 'Powdered graphite is useful as a(n)',
            a: 'a. Adhesive',
            b: 'b. thickening agent',
            c: 'c. fuel',
            d: 'd. lubricant',
            e: 'e. conducting agent',
            ans: 'b'
        },
        {
            id: 10,
            image: ['https://careers4me.in/images/dbda-ma/10.png', '', ''],
            question: 'Which shaft is turning fastest?',
            a: 'a. A',
            b: 'b. B',
            c: 'c. C',
            d: 'd. A and C',
            e: 'e. they will all move at the same speed.',
            ans: 'e'
        },
        {
            id: 11,
            image: ['', '', ''],
            question: 'In which of the following lists are the four metals arranged from hardest to softest?',
            a: 'a. steel, copper, iron, lead',
            b: 'b. steel, iron, lead, copper',
            c: 'c. iron, steel, copper, lead',
            d: 'd. iron, steel, lead, copper',
            e: 'e. steel, iron, copper, lead',
            ans: 'd'
        },
        {
            id: 12,
            image: ['https://careers4me.in/images/dbda-ma/12.png', '', ''],
            question: 'In which direction and how fast is Wheel X turning as the treadle moves in the direction of the arrow?',
            a: 'a. direction A and faster than wheel Y',
            b: 'b. direction A and slower than wheel Y',
            c: 'c. direction B and faster than wheel Y',
            d: 'd. direction B and slower than wheel Y',
            e: 'e. direction A and the same speed as wheel Y',
            ans: 'c'
        },
        {
            id: 13,
            image: ['', '', ''],
            question: 'A basic part of thermostats is',
            a: 'a. a knife switch',
            b: 'b. a bimetallic strip',
            c: 'c. a spring activated relay',
            d: 'd. an electromagnetic relay',
            e: 'e. a photo-sensitive activating device',
            ans: 'e'
        },
        {
            id: 14,
            image: ['https://careers4me.in/images/dbda-ma/14.png', '', ''],
            question: 'What do we call the part of this tool that is marked with an X?',
            a: 'a. bit',
            b: 'b. housing',
            c: 'c. chuck',
            d: 'd. reduction gear',
            e: 'e. key',
            ans: 'e'
        },
        {
            id: 15,
            image: ['https://careers4me.in/images/dbda-ma/15.png', '', ''],
            question: 'This tool is used by a mason for',
            a: 'a. making holes',
            b: 'b. measuring thickness of walls',
            c: 'c. cementing bricks',
            d: 'd. examining the straightness of walls',
            e: 'e. writing on the walls',
            ans: 'b'
        },
        {
            id: 16,
            image: ['', '', ''],
            question: 'An automobile carburettor',
            a: 'a. provides a coupling between front and rear wheels',
            b: 'b. changes direct electrical current to alternating current',
            c: 'c. allows the rear wheels to turn at a greater speed',
            d: 'd. converts the up and down of the pistons',
            e: 'e. allows the mixture of air and petrol',
            ans: 'c'
        },
        {
            id: 17,
            image: ['https://careers4me.in/images/dbda-ma/17.png', '', ''],
            question: 'The Shaft in the picture cannot move from one side to other, but it can turn. As the shaft is turned in the direction shown by the arrow, which way will the gear turn?',
            a: 'a. A',
            b: 'b. B',
            c: 'c. alternatively A and B',
            d: 'd. first A, then B',
            e: 'e. the gear will not turn',
            ans: 'd'
        },
        {
            id: 18,
            image: ['https://careers4me.in/images/dbda-ma/18.png', '', ''],
            question: 'In which circuit is the voltage always the same across each resistor, regardless of the values of R1 and R2?',
            a: 'a. A',
            b: 'b. B',
            c: 'c. both A and B',
            d: 'd. neither A and B',
            e: 'e. it depends upon the resistence',
            ans: 'a'
        },
        {
            id: 19,
            image: ['https://careers4me.in/images/dbda-ma/19.png', '', ''],
            question: 'After hitting the black coin, which way is the striker likely to go on the carom- board?',
            a: 'a. A',
            b: 'b. B',
            c: 'c. C',
            d: 'd. D',
            e: 'e. it will rebound',
            ans: 'd'
        },
        {
            id: 20,
            image: ['https://careers4me.in/images/dbda-ma/20.png', '', ''],
            question: 'If same kind of coins are dropped in these two beakers, which coin will drown faster?',
            a: 'a. A',
            b: 'b. B',
            c: 'c. both will take equal time',
            d: 'd. coin in the beaker A will float',
            e: 'e. coin in the beaker B will float',
            ans: 'e'
        },
        {
            id: 21,
            image: ['https://careers4me.in/images/dbda-ma/21.png', '', ''],
            question: 'Which of these wooden boxes is the heaviest?',
            a: 'a. A',
            b: 'b. B',
            c: 'c. C',
            d: 'd. all are equal in weight',
            e: 'e. B and C are the heaviest',
            ans: 'e'
        },
        {
            id: 22,
            image: ['https://careers4me.in/images/dbda-ma/22.png', '', ''],
            question: 'Which of these shelves will hold the maximum weight?',
            a: 'a. A',
            b: 'b. B',
            c: 'c. C',
            d: 'd. All will hold equal weight',
            e: 'e. A and B will hold same weight',
            ans: 'e'
        },
        {
            id: 23,
            image: ['https://careers4me.in/images/dbda-ma/23.png', '', ''],
            question: 'How will figure X will look in the mirror? Choose from the figures given below:',
            a: 'a. A',
            b: 'b. B',
            c: 'c. C',
            d: 'd. D',
            e: 'e. E',
            ans: 'e'
        },
        {
            id: 24,
            image: ['https://careers4me.in/images/dbda-ma/24.png', '', ''],
            question: 'Which of these glass will break easily when poured with very hot liquid?',
            a: 'a. A',
            b: 'b. B',
            c: 'c. both will break at the same time',
            d: 'd. both can retain the hot liquid',
            e: 'e. hot liquid cannot be poured in A',
            ans: 'e'
        },
        {
            id: 25,
            image: ['https://careers4me.in/images/dbda-ma/25.png', '', ''],
            question: 'Find out the missing number?',
            a: 'a. 17',
            b: 'b. 22',
            c: 'c. 32',
            d: 'd. 8',
            e: 'e. 40',
            ans: 'e'
        },
    ],
    'DBDA-CLA': [
        {
            id: 1,
            a: '1013295',
            b: '1012395',
        },
        {
            id: 2,
            a: 'krqpdisu',
            b: 'krqpdisu',
        },
        {
            id: 3,
            a: '79318453',
            b: '79318435',
        },
        {
            id: 4,
            a: 'KLSQAEPD',
            b: 'KLSQAEPD',
        },
        {
            id: 5,
            a: 'pdesqidt',
            b: 'pdesqidt',
        },
        {
            id: 6,
            a: 'JQRASMNP',
            b: 'JQRASNMP',
        },
        {
            id: 7,
            a: 'RMAPIQUV',
            b: 'RMAPIQUV',
        },
        {
            id: 8,
            a: 'jnlvupdi',
            b: 'jnluvpdi',
        },
        {
            id: 9,
            a: '59412675',
            b: '59412765',
        },
        {
            id: 10,
            a: 'jlnvoprdi',
            b: 'jlvnoprdi',
        },
        {
            id: 11,
            a: '3645179',
            b: '3645179',
        },
        {
            id: 12,
            a: 'KRINQUSR',
            b: 'KRINQUSR',
        },
        {
            id: 13,
            a: 'VFGARTUX',
            b: 'VFGARUTX',
        },
        {
            id: 14,
            a: '732837914',
            b: '732387914',
        },
        {
            id: 15,
            a: 'JVOPIMURA',
            b: 'JVOPIMURA',
        },
        {
            id: 16,
            a: 'bxvinygru',
            b: 'bxvinygur',
        },
        {
            id: 17,
            a: '784513962',
            b: '784513926',
        },
        {
            id: 18,
            a: 'ARQULEWVP',
            b: 'ARQULEVWP',
        },
        {
            id: 19,
            a: 'mnovprydc',
            b: 'mnovprdyc',
        },
        {
            id: 20,
            a: '793148252',
            b: '793148252',
        },
        {
            id: 21,
            a: 'XYBWUTYR',
            b: 'XYBWUTYR',
        },
        {
            id: 22,
            a: 'zrmpfdgh',
            b: 'zrmpfgdh',
        },
        {
            id: 23,
            a: '825731496',
            b: '825731496',
        },
        {
            id: 24,
            a: 'HUMPRJEQ',
            b: 'HUMPREJQ',
        },
        {
            id: 25,
            a: 'derpqmljn',
            b: 'derpqmljn',
        },
        {
            id: 26,
            a: '592614826',
            b: '592614286',
        },
        {
            id: 27,
            a: 'PDLMARQUJ',
            b: 'PDLMARQUJ',
        },
        {
            id: 28,
            a: 'iqrspdelm',
            b: 'iqsrpdelm',
        },
        {
            id: 29,
            a: 'JMNVUPAL',
            b: 'JMNUVPAL',
        },
        {
            id: 30,
            a: '913826574',
            b: '913826574',
        },
        {
            id: 31,
            a: 'cpdekrsqb',
            b: 'cpdekrsqb',
        },
        {
            id: 32,
            a: 'AXPRWNBG',
            b: 'AXPRWNBG',
        },
        {
            id: 33,
            a: '294733506',
            b: '294733506',
        },
        {
            id: 34,
            a: 'CDJLQRSKN',
            b: 'CDJLQRSKN',
        },
        {
            id: 35,
            a: 'reqlmjxap',
            b: 'reqlmjxap',
        },
        {
            id: 36,
            a: 'ZYBMOPRQS',
            b: 'ZYBMOPRQS',
        }
    ],
    'DBDA-CLB': [
        {
            id: 37,
            a: '3814569',
            b: '1012395',
        },
        {
            id: 38,
            a: 'qmlavwn',
            b: 'qmlavwn',
        },
        {
            id: 39,
            a: 'ERYNODFP',
            b: 'ERYNODFP',
        },
        {
            id: 40,
            a: '187935824',
            b: '187953824',
        },
        {
            id: 41,
            a: 'NMRAPCUH',
            b: 'NMRAPCUH',
        },
        {
            id: 42,
            a: 'fvbosdrn',
            b: 'fvbosrdn',
        },
        {
            id: 43,
            a: '631425719',
            b: '631425719',
        },
        {
            id: 44,
            a: 'TRCHLMOV',
            b: 'TRCHMLOV',
        },
        {
            id: 45,
            a: 'egierduj',
            b: 'egiedruj',
        },
        {
            id: 46,
            a: 'DNWLRVAX',
            b: 'DNWLRAXV',
        },
        {
            id: 47,
            a: 'hijuvmlpr',
            b: 'hijuvmlpr',
        },
        {
            id: 48,
            a: 'GVAPORJM',
            b: 'GVAPORJM',
        },
        {
            id: 49,
            a: '81423596',
            b: '81243596',
        },
        {
            id: 50,
            a: 'KIRJSBMO',
            b: 'KIRJSBMO',
        },
        {
            id: 51,
            a: 'oqxjarpd',
            b: 'oqxjaprd',
        },
        {
            id: 52,
            a: '64937215',
            b: '64937215',
        },
        {
            id: 53,
            a: 'XBAJZPOR',
            b: 'XBAJZPOR',
        },
        {
            id: 54,
            a: 'qzbrsaqt',
            b: 'qzbrsaqt',
        },
        {
            id: 55,
            a: '63495712',
            b: '63459712',
        },
        {
            id: 56,
            a: 'PMAZRSTQE',
            b: 'PMAZRSTQE',
        },
        {
            id: 57,
            a: 'bcxpudcf',
            b: 'bcxpudcf',
        },
        {
            id: 58,
            a: '51936247',
            b: '51936247',
        },
        {
            id: 59,
            a: 'JXRASPUV',
            b: 'JXRASPVU',
        },
        {
            id: 60,
            a: 'drpewye',
            b: 'drepwye',
        },
        {
            id: 61,
            a: '4562908',
            b: '4569208',
        },
        {
            id: 62,
            a: 'MDACPURN',
            b: 'MDACPURN',
        },
        {
            id: 63,
            a: 'lmnrdopsa',
            b: 'lmnrdapso',
        },
        {
            id: 64,
            a: '271659325',
            b: '271695325',
        },
        {
            id: 65,
            a: 'IPRSABJM',
            b: 'IPRSABJM',
        },
        {
            id: 66,
            a: 'brpdavul',
            b: 'brpdauvl',
        },
        {
            id: 67,
            a: '91352748',
            b: '91352748',
        },
        {
            id: 68,
            a: 'KRIMNFOJ',
            b: 'KRINMFOJ',
        },
        {
            id: 69,
            a: '61825739',
            b: '61825739',
        },
        {
            id: 70,
            a: 'MPORSJNV',
            b: 'MPORSJNV',
        },
        {
            id: 71,
            a: 'rbapdnuz',
            b: 'rbapdnuz',
        },
        {
            id: 72,
            a: 'LKUVMPOZ',
            b: 'LKVUMPOZ',
        },
    ],
    'DBDA-RA': [
        {
            id: 1,
            a: 'a. DEPD',
            b: 'b. RFMR',
            c: 'c. SJUS',
            d: 'd. TVWT',
            e: 'e. GBBK',
            ans: 'e'
        },
        {
            id: 2,
            a: 'a. XFGK',
            b: 'b. BLMB',
            c: 'c. KQRK',
            d: 'd. DTSD',
            e: 'e. MYZM',
            ans: 'd'
        },
        {
            id: 3,
            a: 'a. FGHE',
            b: 'b. IJKH',
            c: 'c. LMNP',
            d: 'd. RSTQ',
            e: 'e. VWXU',
            ans: 'c'
        },
        {
            id: 4,
            a: 'a. EDDG',
            b: 'b. IHHJ',
            c: 'c. NMMO',
            d: 'd. RQQS',
            e: 'e. TSSU',
            ans: 'a'
        },
        {
            id: 5,
            a: 'a. VWVT',
            b: 'b. SVWV',
            c: 'c. VWVR',
            d: 'd. QVWV',
            e: 'e. VWPV',
            ans: 'b'
        },
        {
            id: 6,
            a: 'a. CDED',
            b: 'b. LMOM',
            c: 'c. PQRQ',
            d: 'd. STUT',
            e: 'e. WXYX',
            ans: 'b'
        },
        {
            id: 7,
            a: 'a. RYAA',
            b: 'b. BBRG',
            c: 'c. RPCC',
            d: 'd. DDRD',
            e: 'e. RLEE',
            ans: 'd'
        },
        {
            id: 8,
            a: 'a. ORAR',
            b: 'b. PGRR',
            c: 'c. RBVR',
            d: 'd. RRUH',
            e: 'e. LRLX',
            ans: 'e'
        },
        {
            id: 9,
            a: 'a. DEGC',
            b: 'b. JKIL',
            c: 'c. MNLO',
            d: 'd. QRPS',
            e: 'e. UVTW',
            ans: 'a'
        },
        {
            id: 10,
            a: 'a. BCFF',
            b: 'b. GHKK',
            c: 'c. KLNN',
            d: 'd. PQSS',
            e: 'e. VWZZ',
            ans: 'c'
        },
        {
            id: 11,
            a: 'a. CGFJ',
            b: 'b. EIHL',
            c: 'c. GKJN',
            d: 'd. IMNR',
            e: 'e. MQPT',
            ans: 'd'
        },
        {
            id: 12,
            a: 'a. HGFC',
            b: 'b. KJIG',
            c: 'c. NMLI',
            d: 'd. TSRO',
            e: 'e. YXWT',
            ans: 'b'
        },
    ],
    'personalityTest': [
        {
            id: 1,
            question: 'Fix electrical things',
        },
        {
            id: 2,
            question: 'Read scientific books or magazines',
        },
        {
            id: 3,
            question: 'Sketch, draw, or paint',
        },
        {
            id: 4,
            question: 'Meet important educators or therapists',
        },
        {
            id: 5,
            question: 'Learn strategies for business success',
        },
        {
            id: 6,
            question: 'Fill out income tax forms',
        },
        {
            id: 7,
            question: 'Repair cars',
        },
        {
            id: 8,
            question: 'Work in a research office or laboratory',
        },
        {
            id: 9,
            question: 'Design furniture, clothing, or posters',
        },
        {
            id: 10,
            question: 'Operate my own service or business',
        },
        {
            id: 11,
            question: 'Read sociology articles or books',
        },
        {
            id: 12,
            question: 'Add, subtract, multiply, and divide numbers in business or bookkeeping',
        },
        {
            id: 13,
            question: 'Fix mechanical things',
        },
        {
            id: 14,
            question: 'Work on a scientific project',
        },
        {
            id: 15,
            question: 'Play in a band, group, or orchestra',
        },
        {
            id: 16,
            question: 'Work for a charity',
        },
        {
            id: 17,
            question: 'Attend sales conferences',
        },
        {
            id: 18,
            question: 'Operate office machines',
        },
        {
            id: 19,
            question: 'Build things with wood',
        },
        {
            id: 20,
            question: 'Study a scientific theory',
        },
        {
            id: 21,
            question: 'Practice a musical instrument',
        },
        {
            id: 22,
            question: 'Help others with their personal problems',
        },
        {
            id: 23,
            question: 'Take a short course on administration or leadership',
        },
        {
            id: 24,
            question: 'Keep detailed records of expenses',
        },
        {
            id: 25,
            question: 'Take a Technology Education (e.g., Industrial Arts, Shop) course',
        },
        {
            id: 26,
            question: 'Work with chemicals',
        },
        {
            id: 27,
            question: 'Create portraits or photographs',
        },
        {
            id: 28,
            question: 'Study juvenile delinquency',
        },
        {
            id: 29,
            question: 'Serve as an officer of any group',
        },
        {
            id: 30,
            question: 'Set up a record-keeping system',
        },
        {
            id: 31,
            question: 'Take a Mechanical Drawing course',
        },
        {
            id: 32,
            question: 'Apply mathematics to practical problems',
        },
        {
            id: 33,
            question: 'Write novels or plays',
        },
        {
            id: 34,
            question: 'Read psychology article or books',
        },
        {
            id: 35,
            question: 'Supervise the work of others',
        },
        {
            id: 36,
            question: 'Take an Accounting course',
        },
        {
            id: 37,
            question: 'Take a Woodworking course',
        },
        {
            id: 38,
            question: 'Take a Physics course',
        },
        {
            id: 39,
            question: 'Take an Art course',
        },
        {
            id: 40,
            question: 'Take a Human Relations course',
        },
        {
            id: 41,
            question: 'Meet important executives and leaders',
        },
        {
            id: 42,
            question: 'Take a Commercial Math Course',
        },
        {
            id: 43,
            question: 'Take an Auto Mechanics course',
        },
        {
            id: 44,
            question: 'Take a Chemistry course',
        },
        {
            id: 45,
            question: 'Arrange or compose music of any kind',
        },
        {
            id: 46,
            question: 'Teach in a high school',
        },
        {
            id: 47,
            question: 'Lead a group in accomplishing some goal',
        },
        {
            id: 48,
            question: 'Take an inventory of supplies or products',
        },
        {
            id: 49,
            question: 'Work with an outstanding mechanic or technician',
        },
        {
            id: 50,
            question: 'Take a Mathematics course',
        },
        {
            id: 51,
            question: 'Work with a gifted artist, writer, or sculptor',
        },
        {
            id: 52,
            question: 'Supervise activities for mentally ill patients',
        },
        {
            id: 53,
            question: 'Participate in a Political campaign',
        },
        {
            id: 54,
            question: 'Check paperwork or products for errors or flaws',
        },
        {
            id: 55,
            question: 'Work outdoors',
        },
        {
            id: 56,
            question: 'Take a Biology course',
        },
        {
            id: 57,
            question: 'Perform for others (dance, sing, act, etc.)',
        },
        {
            id: 58,
            question: 'Teach adults',
        },
        {
            id: 59,
            question: 'Act as an organizational or business consultant',
        },
        {
            id: 60,
            question: 'Update records or files',
        },
        {
            id: 61,
            question: 'Operate motorized machines or equipment',
        },
        {
            id: 62,
            question: 'Study scholarly or technical problems',
        },
        {
            id: 63,
            question: 'Read artistic, literary, or musical articles',
        },
        {
            id: 64,
            question: 'Work as a volunteer',
        },
        {
            id: 65,
            question: 'Read business magazines or articles',
        },
        {
            id: 66,
            question: 'Work in an office',
        },
    ],
    'MCMI-III' :[
        {
            id:'1',
            question:'Lately, my strength seems to be draining out of me, even in the morning.',
        },
        {
            id:'2',
            question:'I think highly of rules because they are a good guide to follow.',
        },
        {
            id:'3',
            question:'I enjoy doing so many different things that I can\'t make up my mind what to do first.'
        },
        {
            id:'4',
            question:'I feel weak and tired much of the time.',
        },
        {
            id:'5',
            question:'I know I\'m a superior person, so I don\'t care what people think.',
        },
        {
            id:'6',
            question:'People have never given me enough recognition for the things I\'ve done.'
        },
        {
            id:'7',
            question:'If my family puts pressure on me, I\'m likely to feel angry and resist doing what they want.',
        },
        {
            id:'8',
            question:'People make fun of me behind my back, talking about the way I act or look.',
        },
        {
            id:'9',
            question:'I often criticize people strongly if they annoy me.'
        },
        {
            id:'10',
            question:'What few feelings I seem to have I rarely show to the outside world.',
        },
        {
            id:'11',
            question:'I have a hard time keeping my balance when walking.',
        },
        {
            id:'12',
            question:'I show my feelings easily and quickly.'
        },
        {
            id:'13',
            question:'My drug habits have often gotten me into a good deal of trouble in the past.',
        },
        {
            id:'14',
            question:'Sometimes I can be pretty rough and mean in my relations with my family.',
        },
        {
            id:'15',
            question:'Things that are going well today won\'t last very long.'
        },
        {
            id:'16',
            question:'I  am a very agreeable and submissive person.',
        },
        {
            id:'17',
            question:'As a teenager, I got into lots of trouble because of bad school behavior.',
        },
        {
            id:'18',
            question:'I\'m afraid to get really close to another person because it may end up with my being ridiculed or shamed.'
        },
        {
            id:'19',
            question:'I seem to choose friends who end up mistreating me.',
        },
        {
            id:'20',
            question:'I\'ve had sad thoughts much of my life since I was a child.',
        },
        {
            id:'21',
            question:'I like to flirt with members of the opposite sex.'
        },
        {
            id:'22',
            question:'I\'m a very erratic person, changing my mind and feelings all the time.',
        },
        {
            id:'23',
            question:'Drinking alcohol has never caused me any real problems in my work.',
        },
        {
            id:'24',
            question:'I  began to feel like a failure some years ago.'
        },
        {
            id:'25',
            question:'I feel guilty much of the time for no reason that I know.',
        },
        {
            id:'26',
            question:'Other people envy my abilities.',
        },
        {
            id:'27',
            question:'When I have a choice, I prefer to do things alone.'
        },
        {
            id:'28',
            question:'I think it\'s necessary to place strict controls on the behavior of members of my family.',
        },
        {
            id:'29',
            question:'People usually think of me as a reserved and serious-minded person.'
        },
        {
            id:'30',
            question:'Lately, I have begun to feel like smashing things.',
        },
        {
            id:'31',
            question:'I think I\'m a special person who deserves special attention from others.',
        },
        {
            id:'32',
            question:'I am always looking to make new friends and meet new people.'
        },
        {
            id:'33',
            question:'If someone criticized me for making a mistake, I would quickly point out some of that person\'s mistakes.',
        },
        {
            id:'34',
            question:'Lately, I have gone all to pieces.',
        },
        {
            id:'35',
            question:'I often give up doing things because I\'m afraid I won\'t do them well.'
        },
        {
            id:'36',
            question:'I often let my angry feelings out and then feel terribly guilty about it.',
        },
        {
            id:'37', 
            question:'I very often lose my ability to feel any sensations in parts of my body.'
        },
        {
            id:'38', 
            question:'I do what I want without worrying about its effect on others.'
        },
        {
            id:'39', 
            question:'Taking so-called illegal drugs may be unwise, but in the past I found I needed them.'
        },
        {
            id:'40', 
            question:'I guess I\'m a fearful and inhibited person.'
        },
        {
            id:'41', 
            question:'I\'ve done a number of stupid things on impulse that ended up causing me great trouble.'
        },
        {
            id:'42', 
            question:'I never forgive an insult or forget an embarrassment that someone caused me.'
        },
        {
            id:'43', 
            question:'I often feel sad or tense right after something good has happened to me.'
        },
        {
            id:'44', 
            question:'I feel terribly depressed and sad much of the time now.'
        },
        {
            id:'45', 
            question:'I always try hard to please others, even when I dislike them.'
        },
        {
            id:'46', 
            question:'I\'ve always had less interest in sex than most people do.'
        },
        {
            id:'47', 
            question:'I tend to always blame myself when things go wrong.'
        },
        {
            id:'48', 
            question:'A long time ago, I decided it\'s best to have little to do with people.'
        },
        {
            id:'49', 
            question:'Since I was a child, I have always had to watch out for people who were trying to cheat me.'
        },
        {
            id:'50', 
            question:'I strongly resent "big shots" who always think they can do things better than I can.'
        },
        {
            id:'51', 
            question:'When things get boring, I like to stir up some excitement.'
        },
        {
            id:'52', 
            question:'I have an alcohol problem that has made difficulties for me and my family.'
        },
        {
            id:'53', 
            question:'Punishment never stopped me from doing what I wanted.'
        },
        {
            id:'54', 
            question:'There are many times, when for no reason, I feel very cheerful and full of excitement.'
        },
        {
            id:'55', 
            question:'In recent weeks I feel worn out for no special reason.'
        },
        {
            id:'56', 
            question:'For some time now I\'ve been feeling very guilty because I can\'t do things right anymore.'
        },
        {
            id:'57', 
            question:'I think I am a very sociable and outgoing person.'
        },
        {
            id:'58', 
            question:'I\'ve become very jumpy in the last few weeks.'
        },
        {
            id:'59', 
            question:'I keep very close track of my money so I am prepared if a need comes up.'
        },
        {
            id:'60', 
            question:'I just haven\'t had the luck in life that others have had.'
        },
        {
            id:'61', 
            question:'Ideas keep turning over and over in my mind and they won\'t go away.'
        },
        {
            id:'62', 
            question:'I\'ve become quite discouraged and sad about life in the past year or two.'
        },
        {
            id:'63', 
            question:'Many people have been spying into my private life for years.'
        },
        {
            id:'64', 
            question:'I don\'t know why, but I sometimes say cruel things just to make others unhappy.'
        },
        {
            id:'65', 
            question:'I flew across the Atlantic 30 times last year.'
        },
        {
            id:'66', 
            question:'My habit of abusing drugs has caused me to miss work in the past.'
        },
        {
            id:'67', 
            question:'I have many ideas that are ahead of the times.'
        },
        {
            id:'68', 
            question:'Lately, I have to think things over and over again for no good reason.'
        },
        {
            id:' 69', 
            question:'I avoid most social situations because I expect people to criticize or reject me.'
        },
        {
            id:'70',
            question:"I often think that I don't deserve the good things that happen to me."
        },
        {
            id:'71',
            question:"When I'm alone, I often feel the strong presence of someone nearby who can't be seen."
        },
        {
            id:'72',
            question:"I feel pretty aimless and don't know where I'm going in life."
        },
        {
            id:'73',
            question:"I often allow others to make important decisions for me."
        },
        {
            id:'74',
            question:"I can't seem to sleep, and wake up just as tired as when I went to bed."
        },
        {
            id:'75',
            question:"Lately, I've been sweating a great deal and feel very tense."
        },
        {
            id:'76',
            question:"I keep having strange thoughts that I wish I could get rid of."
        },
        {
            id:'77',
            question:"I have a great deal of trouble trying to control an impulse to drink to excess."
        },
        {
            id:'78',
            question:"Even when I'm awake, I don't seem to notice people who are near me."
        },
        {
            id:'79',
            question:"I am often cross and grouchy."
        },
        {
            id:'80',
            question:"It is very easy for me to make many friends."
        },
        {
            id:'81',
            question:"I'm ashamed of some of the abuses I suffered when I was young."
        },
        {
            id:'82',
            question:"I always make sure that my work is well planned and organized."
        },
        {
            id:'83',
            question:"My moods seem to change a great deal from one day to the next."
        },
        {
            id:'84',
            question:"I'm too unsure of myself to risk trying something new."
        },
        {
            id:'85',
            question:"I don't blame anyone who takes advantage of someone who allows it."
        },
        {
            id:'86',
            question:"For some time now I've been feeling sad and blue and can't seem to snap out of it."
        },
        {
            id:'87',
            question:"I often get angry with people who do things slowly."
        },
        {
            id:'88',
            question:"I never sit on the sidelines when I'm at a party."
        },
        {
            id:'89',
            question:"I watch my family closely so I'll know who can and who can't be trusted."
        },
        {
            id:'90',
            question:"I sometimes get confused and feel upset when people are kind to me."
        },
        {
            id:'91',
            question:"My use of so-called illegal drugs has led to family arguments."
        },
        {
            id:'92',
            question:"I'm alone most of the time and I prefer it that way."
        },
        {
            id:'93',
            question:"There are members of my family who say I'm selfish and think only of myself."
        },
        {
            id:'94',
            question:"People can easily change my ideas, even if I thought my mind was made up."
        },
        {
            id:'95',
            question:"I often make people angry by bossing them."
        },
        {
            id:'96',
            question:"People have said in the past that I became too interested and too excited about too many things."
        },
        {
            id:'97',
            question:'I believe in the saying, "early to bed and early to rise..."'
        },
        {
            id:'98',
            question:"My feelings toward important people in my life often swing from loving them to hating them."
        },
        {
            id:'99',
            question:"In social groups I am almost always very self-conscious and tense."
        },
        {
            id:'100',
            question:"I guess I'm no different from my parents in becoming somewhat of an alcoholic."
        },
        {
            id:'101',
            question:"I guess I don't take many of my family responsibilities as seriously as I should."
        },
        {
            id:'102',
            question:"Ever since I was a child, I have been losing touch with the real world."
        },
        {
            id:'103',
            question:"Sneaky people often try to get the credit for things I have done or thought of."
        },
        {
            id:'104',
            question:"I can't experience much pleasure because I don't feel I deserve it."
        },
        {
            id:'105',
            question:"I have little desire for close friendships."
        },
        {
            id:'106',
            question:"I've had many periods in my life when I was so cheerful and used up so much energy that I fell into a low mood."
        },
        {
            id:'107',
            question:"I have completely lost my appetite and have trouble sleeping most nights."
        },
        {
            id:'108',
            question:"I worry a great deal about being left alone and having to take care of myself."
        },
        {
            id:'109',
            question:"The memory of a very upsetting experience in my past keeps coming back to haunt my thoughts."
        },
        {
            id:'110',
            question:"I was on the front cover of several magazines last year."
        },
        {
            id:'111',
            question:"I seem to have lost interest in most things that I used to find pleasurable, such as sex."
        },
        {
            id:'112',
            question:"I have been downhearted and sad much of my life since I was quite young."
        },
        {
            id:'113',
            question:"I've gotten into trouble with the law a couple of times."
        },
        {
            id:'114',
            question:"A good way to avoid mistakes is to have a routine for doing things."
        },
        {
            id:'115',
            question:"Other people often blame me for things I didn't do."
        },
        {
            id:'116',
            question:"I have had to be really rough with some people to keep them in line."
        },
        {
            id:'117',
            question:"People think I sometimes talk about strange or different things than they do."
        },
        {
            id:'118',
            question:"There have been times when I couldn't get through the day without some street drugs."
        },
        {
            id:'119',
            question:"People are trying to make me believe that I'm crazy."
        },
        {
            id:'120',
            question:"I'll do something desperate to prevent a person I love from abandoning me."
        },
        {
            id:'121',
            question:"I go on eating binges a couple of times a week."
        },
        {
            id:'122',
            question:"I seem to make a mess of good opportunities that come my way."
        },
        {
            id:'123',
            question:"I've always had a hard time stopping myself from feeling blue and unhappy."
        },
        {
            id:'124',
            question:"When I'm alone and away from home, I often begin to feel tense and panicky."
        },
        {
            id:'125',
            question:"People sometimes get annoyed with me because they say I talk too much or too fast for them."
        },
        {
            id:'126',
            question:"Most successful people today have been either lucky or dishonest."
        },
        {
            id:'127',
            question:"I won't get involved with people unless I'm sure they'll like me."
        },
        {
            id:'128',
            question:"I feel deeply depressed for no reason I can figure out."
        },
        {
            id:'129',
            question:"Years later I still have nightmares about an event that was a real threat to my life."
        },
        {
            id:'130',
            question:"I don't have the energy to concentrate on my everyday responsibilities anymore."
        },
        {
            id:'131',
            question:"Drinking alcohol helps when I'm feeling down."
        },
        {
            id:'132',
            question:"I hate to think about some of the ways I was abused as a child."
        },
        {
            id:'133',
            question:"Even in good times, I've always been afraid that things would soon go bad."
        },
        {
            id:'134',
            question:"I sometimes feel crazy-like or unreal when things start to go badly in my life."
        },
        {
            id:'135',
            question:"Being alone, without the help of someone close to depend on, really frightens me."
        },
        {
            id:'136',
            question:"I know I've spent more money than I should buying illegal drugs."
        },
        {
            id:'137',
            question:"I always see to it that my work is finished before taking time out for leisure activities."
        },
        {
            id:'138',
            question:"I can tell that people are talking about me when I pass by them."
        },
        {
            id:'139',
            question:"I’m very good at making up excuses when I get into trouble."
        },
        {
            id:'140',
            question:"I believe I'm being plotted against."
        },
        {
            id:'141',
            question:"I feel that most people think poorly of me."
        },
        {
            id:'142',
            question:"I frequently feel there's nothing inside me, like I'm empty and hollow."
        },
        {
            id:'143',
            question:"I sometimes force myself to vomit after eating."
        },
        {
            id:'144',
            question:"I guess I go out of my way to encourage people to admire the things I say or do."
        },
        {
            id:'145',
            question:"I spend my life worrying over one thing or another."
        },
        {
            id:'146',
            question:"I always wonder what the real reason is when someone is acting especially nice to me."
        },
        {
            id:'147',
            question:"There are certain thoughts that keep coming back again and again in my mind."
        },
        {
            id:'148',
            question:"Few things in life give me pleasure."
        },
        {
            id:'149',
            question:"I feel shaky and have difficulty falling asleep because painful memories of a past event keep running through my mind."
        },
        {
            id:'150',
            question:"Looking ahead as each day begins makes me feel terribly depressed."
        },
        {
            id:'151',
            question:"I've never been able to shake the feeling that I'm worthless to others."
        },
        {
            id:'152',
            question:"I have a drinking problem that I've tried unsuccessfully to end."
        },
        {
            id:'153',
            question:"Someone has been trying to control my mind."
        },
        {
            id:'154',
            question:"I have tried to commit suicide."
        },
        {
            id:'155',
            question:"I'm willing to starve myself to be even thinner than I am."
        },
        {
            id:'156',
            question:"I don't understand why some people smile at me."
        },
        {
            id:'157',
            question:"I have not seen a car in the last ten years."
        },
        {
            id:'158',
            question:"I get very tense with people I don't know well because they may want to harm me."
        },
        {
            id:'159',
            question:"Someone would have to be pretty exceptional to understand my special abilities."
        },
        {
            id:'160',
            question:"My current life is still upset by flashbacks of something terrible that happened to me."
        },
        {
            id:'161',
            question:"I seem to create situations with others in which I get hurt or feel rejected."
        },
        {
            id:'162',
            question:"I often get lost in my thoughts and forget what's going on around me."
        },
        {
            id:'163',
            question:"People say I'm a thin person, but I feel that my thighs and backside are much too big."
        },
        {
            id:'164',
            question:"There are terrible events from my past that come back repeatedly to haunt my thoughts and dreams."
        },
        {
            id:'165',
            question:"Other than my family, I have no close friends."
        },
        {
            id:'166',
            question:"I act quickly much of the time and don't think things through as I should."
        },
        {
            id:'167',
            question:"I take great care to keep my life a private matter so no one can take advantage of me."
        },
        {
            id:'168',
            question:"I very often hear things so well that it bothers me."
        },
        {
            id:'169',
            question:"I'm always willing to give in to others in a disagreement because I fear their anger or rejection."
        },
        {
            id:'170',
            question:"I repeat certain behaviors again and again, sometimes to reduce my anxiety and sometimes to stop something bad from happening."
        },
        {
            id:'171',
            question:"I have given serious thought recently to doing away with myself."
        },
        {
            id:'172',
            question:"People tell me that I'm a very proper and moral person."
        },
        {
            id:'173',
            question:"I still feel terrified when I think of a traumatic experience I had years ago."
        },
        {
            id:'174',
            question:"Although I'm afraid to make friendships, I wish I had more than I do."
        },
        {
            id:'175',
            question:"There are people who are supposed to be my friends who would like to do me harm."
        }

    ]
}

export default questions