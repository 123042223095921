import React, { Fragment, useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { Typography, Grid, Radio, RadioGroup, FormControlLabel, FormControl, Button } from '@material-ui/core';
import Questions from '../Questions';
import Timer from '../Timer';
import Constants from '../../Constants'
import useProfile from '../../Hooks/useProfile'
import Quiz from '../Quiz';
import { toast } from 'react-toastify'

const QuizCompo = React.memo(() => {

    const history = useHistory();

    const [question5, setQuestion5] = useState([]);
    const [disableQuestion, setDisableQuestion] = useState(false);

    const { loader, showLoader, hideLoader } = useProfile();

    // Set Answer5 State
    const [answer5, setAnswer5] = useState({
        question1: "", question2: "", question3: "", question4: "", question5: "", question6: "", question7: "",
        question8: "", question9: "", question10: "", question11: "", question12: "", question13: "", question14: "",
        question15: "", question16: "", question17: "", question18: "", question19: "", question20: "",
        question21: "", question22: "", question23: "", question24: "", question25: "", question26: "", question27: "",
        question28: "", question29: "", question30: "", question31: "", question32: "", question33: "", question34: "",
        question35: "", question36: "", question37: "", question38: "", question39: "", question40: "",
        question41: "", question42: "", question43: "", question44: "", question45: "", question46: "", question47: "",
        question48: "", question49: "", question50: "", question51: "", question52: "", question53: "", question54: "",
        question55: "", question56: "", question57: "", question58: "", question59: "", question60: "",
        question61: "", question62: "", question63: "", question64: "", question65: "", question66: "", question67: "",
        question68: "", question69: "", question70: "", question71: "", question72: "",
        ans1: "d", ans2: "s", ans3: "d", ans4: "s", ans5: "s", ans6: "d", ans7: "s",
        ans8: "d", ans9: "d", ans10: "s", ans11: "s", ans12: "s", ans13: "d", ans14: "d",
        ans15: "s", ans16: "d", ans17: "d", ans18: "s", ans19: "s", ans20: "s",
        ans21: "s", ans22: "d", ans23: "s", ans24: "d", ans25: "s", ans26: "d", ans27: "s",
        ans28: "d", ans29: "d", ans30: "s", ans31: "s", ans32: "s", ans33: "s", ans34: "d",
        ans35: "d", ans36: "s", ans37: "d", ans38: "s", ans39: "s", ans40: "d",
        ans41: "s", ans42: "d", ans43: "s", ans44: "d", ans45: "d", ans46: "d", ans47: "s",
        ans48: "s", ans49: "d", ans50: "s", ans51: "d", ans52: "s", ans53: "s", ans54: "s",
        ans55: "d", ans56: "s", ans57: "s", ans58: "s", ans59: "d", ans60: "d",
        ans61: "d", ans62: "s", ans63: "d", ans64: "d", ans65: "s", ans66: "d", ans67: "s",
        ans68: "d", ans69: "s", ans70: "s", ans71: "s", ans72: "d",
    });

    // HandleChange Input5
    const handleChange = (e) => {
        setAnswer5((prevProps) => ({
            ...prevProps,
            [e.target.name]: e.target.value
        }));
    }

    const submitHandler = async () => {
        showLoader();
        const response = await fetch(Constants.url + 'questions/question5', {
            method: 'POST',
            headers: {
                'Content-type': 'application/json',
                'Authorization': `Bearer ${JSON.parse(localStorage.getItem('token'))}`
            },
            body: JSON.stringify(answer5)
        });
        const result = await response.json();
        if (result.status === 'success') {
            history.push('/dbda_ma')
            sessionStorage.setItem('Qpoints',JSON.stringify({q1p1:'1',q1p2:'1',q2:'1',q3:'1',q4:'1',q5:'0',q6:'0',q7:'0',pq:'0'}));
            hideLoader()
            toast.success(result.message);
        }
        else {
            hideLoader()
            toast.error(result.message);
            return false;
        }
    }

    
    useEffect(() => {
        setQuestion5(Questions['DBDA-SA'])
    },[])

    return (

        <Fragment>
            <div>
                <Typography variant="h6" color="inherit" >SA-Spatial Ability</Typography>
                <Timer initialMinute="6" initialSeconds="1" setDisableQuestion={setDisableQuestion} hideonCheck="1"></Timer>
                <Grid container direction="row" spacing={2} justify="center" alignItems="center" className="Questions row_base7">
                    {
                        question5.map((item, index) => (
                            <Fragment key={index} >
                                <Grid item xs={12} sm={4} md={3} lg={3}>
                                    <span className="me-3 dot">{item.id}.</span>
                                    <img src={item.question} className="img-fluid" alt={item.question} height="100px" width="100px" />
                                </Grid>
                                <Grid item xs={12} sm={8} md={9} lg={9} className="setflex">
                                    <div className="set1">
                                        <p>{item.l}.</p>
                                        <img src={item.a} className="img-fluid me-md-5" alt={item.a} height="80px" width="80px" /><br />
                                        <FormControl component="fieldset">
                                            <RadioGroup aria-label={`question${item.l}`} name={`question${item.l}`} value={answer5.question} onChange={handleChange}>
                                                <FormControlLabel value='s' control={<Radio />} label='S' disabled={disableQuestion} />
                                                <FormControlLabel value='r' control={<Radio />} label='R' disabled={disableQuestion} />
                                            </RadioGroup>
                                        </FormControl>
                                    </div>
                                    <div className="set2">
                                        <p>{item.m}.</p>
                                        <img src={item.b} className="img-fluid me-md-5" alt={item.b} height="80px" width="80px" /><br />
                                        <FormControl component="fieldset">
                                            <RadioGroup aria-label={`question${item.m}`} name={`question${item.m}`} value={answer5.question} onChange={handleChange}>
                                                <FormControlLabel value='s' control={<Radio />} label='S' disabled={disableQuestion} />
                                                <FormControlLabel value='r' control={<Radio />} label='R' disabled={disableQuestion} />
                                            </RadioGroup>
                                        </FormControl>
                                    </div>
                                    <div className="set3">
                                        <p>{item.n}.</p>
                                        <img src={item.c} className="img-fluid me-md-5" alt={item.c} height="80px" width="80px" /><br />
                                        <FormControl component="fieldset">
                                            <RadioGroup aria-label={`question${item.n}`} name={`question${item.n}`} value={answer5.question} onChange={handleChange}>
                                                <FormControlLabel value='s' control={<Radio />} label='S' disabled={disableQuestion} />
                                                <FormControlLabel value='r' control={<Radio />} label='R' disabled={disableQuestion} />
                                            </RadioGroup>
                                        </FormControl>
                                    </div>
                                    <div className="set4">
                                        <p>{item.o}.</p>
                                        <img src={item.d} className="img-fluid me-md-5" alt={item.d} height="80px" width="80px" /><br />
                                        <FormControl component="fieldset">
                                            <RadioGroup aria-label={`question${item.o}`} name={`question${item.o}`} value={answer5.question} onChange={handleChange}>
                                                <FormControlLabel value='s' control={<Radio />} label='S' disabled={disableQuestion} />
                                                <FormControlLabel value='r' control={<Radio />} label='R' disabled={disableQuestion} />
                                            </RadioGroup>
                                        </FormControl>
                                    </div>
                                    <div className="set5">
                                        <p>{item.p}.</p>
                                        <img src={item.e} className="img-fluid me-md-5" alt={item.e} height="80px" width="80px" /><br />
                                        <FormControl component="fieldset">
                                            <RadioGroup aria-label={`question${item.p}`} name={`question${item.p}`} value={answer5.question} onChange={handleChange}>
                                                <FormControlLabel value='s' control={<Radio />} label='S' disabled={disableQuestion} />
                                                <FormControlLabel value='r' control={<Radio />} label='R' disabled={disableQuestion} />
                                            </RadioGroup>
                                        </FormControl>
                                    </div>
                                    <div className="set6">
                                        <p>{item.q}.</p>
                                        <img src={item.f} className="img-fluid me-md-5" alt={item.f} height="80px" width="80px" /><br />
                                        <FormControl component="fieldset">
                                            <RadioGroup aria-label={`question${item.q}`} name={`question${item.q}`} value={answer5.question} onChange={handleChange}>
                                                <FormControlLabel value='s' control={<Radio />} label='S' disabled={disableQuestion} />
                                                <FormControlLabel value='r' control={<Radio />} label='R' disabled={disableQuestion} />
                                            </RadioGroup>
                                        </FormControl>
                                    </div>
                                </Grid>
                                <span className="border"></span>
                            </Fragment>
                        ))
                    }
                </Grid>
            </div>
            <div>
                <Fragment>
                    <Button variant="contained" color="primary" onClick={submitHandler}>
                        Next</Button>
                </Fragment>
            </div>
            {loader}
        </Fragment>
    )
});

const Question5 = React.memo(() => {

    return (
        <Quiz QuizCompo={QuizCompo}/>
    )
});

export default Question5
