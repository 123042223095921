import React, { Fragment } from 'react';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
// import FormLabel from '@material-ui/core/FormLabel';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';

const Payment = ({ price, values,title, handleChange }) => {

    return (
        <Fragment>
            <Typography variant="h6" color="inherit" style={{ margin: "20px" }}>{title}</Typography>
            <Grid item xs={12} sm={12} style={{ textAlign: "center" }}>
                <FormControl component="fieldset">
                    {/* <FormLabel component="legend">Select Price</FormLabel> */}
                    <RadioGroup aria-label="amount" name="amount" value={values.amount} onChange={handleChange} style={{ display: 'inline' }}>
                        <FormControlLabel value={price} control={<Radio />} label={`${price} Rs`} />
                    </RadioGroup>
                </FormControl>
            </Grid>
        </Fragment>
    );
};

export default Payment;