import './App.css';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import Disclaimer from './Components/Pages/Disclaimer';
import Terms_condition from './Components/Pages/Terms_condition';
import Privacy from './Components/Pages/Privacy';
import Refund from './Components/Pages/Refund';
import Quizselection from './Components/Pages/Quizselection';
import Login from './Components/Auth/Login';
import Register from './Components/Auth/Register';
import Dashboard from './Components/Dashboard/Dashboard';
// import Quiz from './Components/Quiz/Quiz';
import Intro from './Components/Quiz/Videos/Intro';
import End from './Components/Quiz/Videos/End';
import Question1 from './Components/Quiz/Questions/Question1';
import Question2 from './Components/Quiz/Questions/Question2';
import Question3 from './Components/Quiz/Questions/Question3';
import Question4 from './Components/Quiz/Questions/Question4';
import Question5 from './Components/Quiz/Questions/Question5';
import Question6 from './Components/Quiz/Questions/Question6';
import Question7 from './Components/Quiz/Questions/Question7';
import Question8 from './Components/Quiz/Questions/Question8';
import PersonalityTest from './Components/Quiz/Questions/PersonalityTest';
import DBDA_VA1 from './Components/Quiz/Videos/DBDA_VA1';
import DBDA_VA2 from './Components/Quiz/Videos/DBDA_VA2';
import DBDA_NA from './Components/Quiz/Videos/DBDA_NA';
import DBDA_CA from './Components/Quiz/Videos/DBDA_CA';
import DBDA_SA from './Components/Quiz/Videos/DBDA_SA';
import DBDA_MA from './Components/Quiz/Videos/DBDA_MA';
import DBDA_CL from './Components/Quiz/Videos/DBDA_CL';
import DBDA_RA from './Components/Quiz/Videos/DBDA_RA';
import Protected from './Components/Protected';
import { ToastContainer, Flip, } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';

const theme = createMuiTheme({
    palette: {
        primary: {
            main: '#46A3DC',
            contrastText: "#fff"
        }
    }
});

const App = () => {

    return (
        <MuiThemeProvider theme={theme}>
            <Router>
                <div className="app">
                    <Switch>
                        <Protected exact path="/" component={Dashboard} ></Protected>
                        <Protected exact path="/disclaimer" component={Disclaimer} ></Protected>
                        <Protected exact path="/terms_condition" component={Terms_condition} ></Protected>
                        <Protected exact path="/privacy_policy" component={Privacy} ></Protected>
                        <Protected exact path="/refund_cancellations" component={Refund} ></Protected>
                        <Protected exact path="/quiz_selection" component={Quizselection} ></Protected>
                        {/* <Protected exact path="/quiz" component={Quiz} ></Protected> */}
                        <Protected exact path="/intro" component={Intro} ></Protected>
                        <Protected exact path="/end" component={End} ></Protected>
                        <Protected exact path="/question1" component={Question1} ></Protected>
                        <Protected exact path="/question2" component={Question2} ></Protected>
                        <Protected exact path="/question3" component={Question3} ></Protected>
                        <Protected exact path="/question4" component={Question4} ></Protected>
                        <Protected exact path="/question5" component={Question5} ></Protected>
                        <Protected exact path="/question6" component={Question6} ></Protected>
                        <Protected exact path="/question7" component={Question7} ></Protected>
                        <Protected exact path="/question8" component={Question8} ></Protected>
                        <Protected exact path="/personalitytest" component={PersonalityTest} ></Protected>
                        <Protected exact path="/dbda_va1" component={DBDA_VA1} ></Protected>
                        <Protected exact path="/dbda_va2" component={DBDA_VA2} ></Protected>
                        <Protected exact path="/dbda_na" component={DBDA_NA} ></Protected>
                        <Protected exact path="/dbda_ca" component={DBDA_CA} ></Protected>
                        <Protected exact path="/dbda_sa" component={DBDA_SA} ></Protected>
                        <Protected exact path="/dbda_ma" component={DBDA_MA} ></Protected>
                        <Protected exact path="/dbda_cl" component={DBDA_CL} ></Protected>
                        <Protected exact path="/dbda_ra" component={DBDA_RA} ></Protected>
                        <Route path="/login" component={Login} ></Route>
                        <Route path="/register" component={Register} ></Route>

                    </Switch>
                    <ToastContainer
                        position="top-center"
                        transition={Flip}
                        autoClose={10000}
                        hideProgressBar={false}
                        newestOnTop={false}
                        closeOnClick
                        rtl={false}
                        pauseOnFocusLoss={false}
                        draggable
                        pauseOnHover />
                </div>
            </Router>
        </MuiThemeProvider>
    );
}

export default App;
