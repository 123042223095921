import React, { Fragment, useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { Typography, Grid, Radio, RadioGroup, FormControlLabel, FormControl, Button } from '@material-ui/core';
import Questions from '../Questions';
import Timer from '../Timer';
import Constants from '../../Constants'
import useProfile from '../../Hooks/useProfile'
import Quiz from '../Quiz';
import { toast } from 'react-toastify'

const QuizCompo = React.memo(() => {

    const history = useHistory();

    const [question3, setQuestion3] = useState([]);
    const [disableQuestion, setDisableQuestion] = useState(false);

    const { loader, showLoader, hideLoader } = useProfile();

    // Set Answer3 State
    const [answer3, setAnswer3] = useState({
        question1: "", question2: "", question3: "", question4: "", question5: "", question6: "", question7: "",
        question8: "", question9: "", question10: "", question11: "", question12: "", question13: "", question14: "",
        question15: "", question16: "", question17: "", question18: "", question19: "", question20: "",
        ans1: "d", ans2: "b", ans3: "c", ans4: "a", ans5: "b", ans6: "d", ans7: "a",
        ans8: "e", ans9: "a", ans10: "c", ans11: "b", ans12: "d", ans13: "e", ans14: "c",
        ans15: "e", ans16: "a", ans17: "b", ans18: "c", ans19: "x", ans20: "d",
    });

    // HandleChange Input3
    const handleChange = (e) => {
        setAnswer3((prevProps) => ({
            ...prevProps,
            [e.target.name]: e.target.value
        }));
    }

    const submitHandler = async () => {
        showLoader();
        const response = await fetch(Constants.url + 'questions/question3', {
            method: 'POST',
            headers: {
                'Content-type': 'application/json',
                'Authorization': `Bearer ${JSON.parse(localStorage.getItem('token'))}`
            },
            body: JSON.stringify(answer3)
        });
        const result = await response.json();
        if (result.status === 'success') {
            history.push('/dbda_ca')
            sessionStorage.setItem('Qpoints',JSON.stringify({q1p1:'1',q1p2:'1',q2:'1',q3:'0',q4:'0',q5:'0',q6:'0',q7:'0',pq:'0'}));
            hideLoader()
            toast.success(result.message);
        }
        else {
            hideLoader()
            toast.error(result.message);
            return false;
        }
    }
    
    useEffect(() => {
        setQuestion3(Questions['DBDA-NA'])
    },[])

    return (
        <Fragment>
            <div>
                <Typography variant="h6" color="inherit" >NA-Numerical ability</Typography>
                <Timer initialMinute="5" initialSeconds="30" setDisableQuestion={setDisableQuestion} hideonCheck="0"></Timer>
                <Grid container direction="row" spacing={2} justify="center" alignItems="center" className="Questions row_base3">
                    {
                        question3.map((item, index) => (
                            <Fragment key={index} >
                                <Grid item xs={6} sm={4} md={3} lg={3}>
                                    <Typography variant="h6" color="inherit"><span className="me-3 dot">{item.id}.</span> {item.question}</Typography>
                                </Grid>
                                <Grid item xs={6} sm={8} md={9} lg={9}>
                                    <FormControl component="fieldset">
                                        <RadioGroup aria-label={`question${item.id}`} name={`question${item.id}`} value={answer3.question} onChange={handleChange}>
                                            <FormControlLabel value='a' control={<Radio />} label={item.a} disabled={disableQuestion} />
                                            <FormControlLabel value='b' control={<Radio />} label={item.b} disabled={disableQuestion} />
                                            <FormControlLabel value='c' control={<Radio />} label={item.c} disabled={disableQuestion} />
                                            <FormControlLabel value='d' control={<Radio />} label={item.d} disabled={disableQuestion} />
                                            <FormControlLabel value='e' control={<Radio />} label={item.e} disabled={disableQuestion} />
                                        </RadioGroup>
                                    </FormControl>
                                </Grid>
                            </Fragment>
                        ))
                    }
                </Grid>
            </div>
            <div>
                <Fragment>
                    <Button variant="contained" color="primary" onClick={submitHandler}>
                        Next</Button>
                </Fragment>
            </div>
            {loader}
        </Fragment>
    )
});

const Question3 = React.memo(() => {

    return (
        <Quiz QuizCompo={QuizCompo}/>
    )
});


export default Question3
