import React, { Fragment, useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { Typography, Grid, Radio, RadioGroup, FormControlLabel, FormControl, Button } from '@material-ui/core';
import Questions from '../Questions';
import Timer from '../Timer';
import Constants from '../../Constants'
import useProfile from '../../Hooks/useProfile'
import Quiz from '../Quiz';
import { toast } from 'react-toastify'

const QuizCompo = React.memo(() => {

    const history = useHistory();

    const [question6, setQuestion6] = useState([]);
    const [disableQuestion, setDisableQuestion] = useState(false);

    const { loader, showLoader, hideLoader } = useProfile();

    // Set Answer6 State
    const [answer6, setAnswer6] = useState({
        question1: "", question2: "", question3: "", question4: "", question5: "", question6: "", question7: "",
        question8: "", question9: "", question10: "", question11: "", question12: "", question13: "", question14: "",
        question15: "", question16: "", question17: "", question18: "", question19: "", question20: "",
        question21: "", question22: "", question23: "", question24: "", question25: "",
        ans1: "d", ans2: "s", ans3: "d", ans4: "s", ans5: "s", ans6: "d", ans7: "s",
        ans8: "d", ans9: "d", ans10: "s", ans11: "s", ans12: "s", ans13: "d", ans14: "d",
        ans15: "s", ans16: "d", ans17: "d", ans18: "s", ans19: "s", ans20: "s",
        ans21: "s", ans22: "d", ans23: "s", ans24: "d", ans25: "s",
    });

    // HandleChange Input6
    const handleChange = (e) => {
        setAnswer6((prevProps) => ({
            ...prevProps,
            [e.target.name]: e.target.value
        }));
    }

    const submitHandler = async () => {
        showLoader();
        const response = await fetch(Constants.url + 'questions/question6', {
            method: 'POST',
            headers: {
                'Content-type': 'application/json',
                'Authorization': `Bearer ${JSON.parse(localStorage.getItem('token'))}`
            },
            body: JSON.stringify(answer6)
        });
        const result = await response.json();
        if (result.status === 'success') {
            history.push('/dbda_cl')
            sessionStorage.setItem('Qpoints',JSON.stringify({q1p1:'1',q1p2:'1',q2:'1',q3:'1',q4:'1',q5:'1',q6:'0',q7:'0',pq:'0'}));
            hideLoader()
            toast.success(result.message);
        }
        else {
            hideLoader()
            toast.error(result.message);
            return false;
        }
    }
    
    useEffect(() => {
        setQuestion6(Questions['DBDA-MA'])
    },[])

    return (
        <Fragment>
            <div>
                <Typography variant="h6" color="inherit" >MA-Mechanical ability</Typography>
                <Timer initialMinute="9" initialSeconds="1" setDisableQuestion={setDisableQuestion} hideonCheck="0"></Timer>
                <Grid container direction="row" spacing={2} justify="center" alignItems="center" className="Questions row_base8">
                    {
                        question6.map((item, index) => (
                            <Fragment key={index} >
                                <Grid item xs={12} sm={12} md={6} lg={6}>
                                    <span className="me-3 dot">{item.id}.</span>
                                    <img src={item.image[0]} className="img-fluid" alt={item.image[0]} />
                                    <img src={item.image[1]} className="img-fluid" alt={item.image[1]} />
                                    <img src={item.image[2]} className="img-fluid" alt={item.image[2]} />
                                </Grid>
                                <Grid item xs={12} sm={12} md={6} lg={6}>
                                    <Typography variant="h6" color="inherit"> {item.question}</Typography>
                                    <FormControl component="fieldset">
                                        <RadioGroup aria-label={`question${item.id}`} name={`question${item.id}`} value={answer6.question} onChange={handleChange}>
                                            <FormControlLabel value='a' control={<Radio />} label={item.a} disabled={disableQuestion} />
                                            <FormControlLabel value='b' control={<Radio />} label={item.b} disabled={disableQuestion} />
                                            <FormControlLabel value='c' control={<Radio />} label={item.c} disabled={disableQuestion} />
                                            <FormControlLabel value='d' control={<Radio />} label={item.d} disabled={disableQuestion} />
                                            <FormControlLabel value='e' control={<Radio />} label={item.e} disabled={disableQuestion} />
                                        </RadioGroup>
                                    </FormControl>
                                </Grid>
                                <span className="border"></span>
                            </Fragment>
                        ))
                    }
                </Grid>
            </div>
            <div>
                <Fragment>
                    <Button variant="contained" color="primary" onClick={submitHandler}>
                        Next</Button>
                </Fragment>
            </div>
            {loader}
        </Fragment>
    )
});

const Question6 = React.memo(() => {

    return (
        <Quiz QuizCompo={QuizCompo}/>
    )
});


export default Question6
