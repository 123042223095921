import React from 'react'
import { useState, useEffect } from 'react';

const Timer = React.memo(({ initialMinute, initialSeconds, setDisableQuestion, hideonCheck }) => {

    // const { initialMinute, initialSeconds } = props;
    const [minutes, setMinutes] = useState(initialMinute);
    const [seconds, setSeconds] = useState(initialSeconds);
    useEffect(() => {

        let myInterval = setInterval(() => {
            if (seconds > 0) {
                setSeconds(seconds - 1);
            }
            if (seconds === 0) {
                if (minutes === 0) {
                    clearInterval(myInterval)
                    setDisableQuestion(true);
                    // setDisableValue(0);
                } else {
                    setMinutes(minutes - 1);
                    setSeconds(59);
                }
            }
        }, 1000)
        return () => {
            clearInterval(myInterval);
        };
    });
    return (
        <div>
            {
                (
                    hideonCheck === '0' ?
                        (
                            minutes === 0 && seconds === 0
                                ? <h4>Times Up Move To Next Question!</h4>
                                : <h4> {minutes}:{seconds < 10 ? `0${seconds}` : seconds}</h4>
                        )
                        :
                        null
                )
            }

        </div>
    )
});

export default Timer;