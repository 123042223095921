import { React, useState, Fragment } from 'react'
import { useHistory, Link } from 'react-router-dom'
import { toast } from 'react-toastify'
import styles from './Auth.module.css'
import Constants from '../Constants'
import useAuth from '../Hooks/useAuth'
import useLoader from '../Hooks/useLoader'

const Auth = () => {

    const history = useHistory();
    const { token } = useAuth();
    const [loader, showLoader, hideLoader] = useLoader();

    //For Registration
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [promocode,setPromocode] = useState('');

    const handleRegisterSubmit = async (e) => {
        e.preventDefault();

        if (!token || sessionStorage.getItem('token') === null) {
            toast.error('Token is Required')
        }
        else if (token !== sessionStorage.getItem('token')) {
            toast.error('Invalid Token')
        }
        else if (!name || !email || !password || !promocode) {
            toast.error('All Fields Are Required')
        }
        else {
            showLoader();
            const userData = { name, email, password, promocode }
            const response = await fetch(Constants.url + 'auth/add', {
                method: 'POST',
                headers: {
                    'Content-type': 'application/json'
                },
                body: JSON.stringify(userData)
            });
            const result = await response.json();
            if (result.status === 'success') {
                hideLoader()
                toast.success(result.message)
                history.push('/login');
            }
            else {
                hideLoader()
                toast.error(result.message)
            }
        }
    }

    return (
        <Fragment>
            <div className={styles.authContainer} id="container">
                <div className={styles.signUpContainer} id="signInShow">
                    <form className={styles.form} onSubmit={handleRegisterSubmit}>
                        <h1 className={styles.heading}>Create Account</h1>
                        {/* <div id="getResponse" className={`alert ${classes}`} role="alert">{response}</div> */}
                        <input type="text" name="name" className={`form-control ${styles.authInput}`} placeholder="Name"
                            value={name} onChange={(e) => setName(e.target.value)} />
                        <input type="email" name="email" className={`form-control ${styles.authInput}`} placeholder="Email"
                            value={email} onChange={(e) => setEmail(e.target.value)} />
                        <input type="password" name="password" className={`form-control ${styles.authInput}`} placeholder="Password"
                            value={password} onChange={(e) => setPassword(e.target.value)} />
                        <input type="text" name="promocode" className={`form-control ${styles.authInput}`} placeholder="Promocode"
                            value={promocode} onChange={(e) => setPromocode(e.target.value)} />
                        <button className={styles.authBtn}>Register</button>
                        <input type="hidden" name="token" value={token}></input>
                        <div className="clearfix"></div>
                        <Link to="/login" className={styles.userAcees}>Existing Student? Click to Login</Link>
                        {/* <Link to="/" className={styles.forgotPass}>Forgot your password ?</Link> */}
                    </form>
                </div>
            </div>
            {loader}
        </Fragment >
    )
}

export default Auth
