import React,{  useEffect,Fragment } from 'react'
// import Videos from '../Videos';
import { useHistory } from 'react-router-dom'
import useFetch from '../../Hooks/useFetch';
import Constants from '../../Constants';
import { Grid, Typography } from '@material-ui/core';
import ReactPlayer from 'react-player';
import { CssBaseline, Container, Paper, Button } from '@material-ui/core';
import useProfile from '../../Hooks/useProfile'
import Topbar from '../../Topbar';
import Footer from '../../Footer';

const Intro = React.memo(() => {

    const history = useHistory();

    const { userData,  logout, handleProfileChange, updateProfile,
        anchorEl, open, handleMenu, handleClose,
        openmodal, handleOpenModal, handleCloseModal } = useProfile();
        
    const { data: userDetailData } = useFetch(Constants.url + 'auth/getUserDetail');
    const { data: payCheck } = useFetch(Constants.url + 'questions/attemp_check/payments'); 
    const { data: quesCheck1 } = useFetch(Constants.url + 'questions/attemp_check/dbda_va');
    const { data: quesCheck2 } = useFetch(Constants.url + 'questions/attemp_check/dbda_na');
    const { data: quesCheck3 } = useFetch(Constants.url + 'questions/attemp_check/dbda_ca');
    const { data: quesCheck4 } = useFetch(Constants.url + 'questions/attemp_check/dbda_sa');
    const { data: quesCheck5 } = useFetch(Constants.url + 'questions/attemp_check/dbda_ma');
    const { data: quesCheck6 } = useFetch(Constants.url + 'questions/attemp_check/dbda_cl');
    const { data: quesCheck7, Loading } = useFetch(Constants.url + 'questions/attemp_check/dbda_ra');
    // const { data: pt_test, Loading } = useFetch(Constants.url + 'questions/attemp_check/personality_test');

    useEffect(() => {
        if(userDetailData && userDetailData.status === 'success') {
            var discount = userDetailData.data.discount;
      
            if(payCheck.check === '0' && discount !== '0') {
                history.push('/');
            }
            else if(quesCheck1.check === '-1' && quesCheck2.check === '0' && quesCheck3.check === '0' && quesCheck4.check === '0' &&
                quesCheck5.check === '0' && quesCheck6.check === '0' && quesCheck7.check === '0') {
                history.push('/dbda_va2');
                sessionStorage.setItem('Qpoints',JSON.stringify({q1p1:'1',q1p2:'0',q2:'0',q3:'0',q4:'0',q5:'0',q6:'0',q7:'0',pq:'0'}));
            }
            else if(quesCheck1.check === '1' && quesCheck2.check === '0' && quesCheck3.check === '0' && quesCheck4.check === '0' &&
                quesCheck5.check === '0' && quesCheck6.check === '0' && quesCheck7.check === '0') {
                history.push('/dbda_na');
                sessionStorage.setItem('Qpoints',JSON.stringify({q1p1:'1',q1p2:'1',q2:'0',q3:'0',q4:'0',q5:'0',q6:'0',q7:'0',pq:'0'}));
            }
            else if(quesCheck1.check === '1' && quesCheck2.check === '1' && quesCheck3.check === '0' && quesCheck4.check === '0' &&
                quesCheck5.check === '0' && quesCheck6.check === '0' && quesCheck7.check === '0') {
                history.push('/dbda_ca');
                sessionStorage.setItem('Qpoints',JSON.stringify({q1p1:'1',q1p2:'1',q2:'1',q3:'0',q4:'0',q5:'0',q6:'0',q7:'0',pq:'0'}));        
            }
            else if(quesCheck1.check === '1' && quesCheck2.check === '1' && quesCheck3.check === '1' && quesCheck4.check === '0' &&
                quesCheck5.check === '0' && quesCheck6.check === '0' && quesCheck7.check === '0') {
                history.push('/dbda_sa');
                sessionStorage.setItem('Qpoints',JSON.stringify({q1p1:'1',q1p2:'1',q2:'1',q3:'1',q4:'0',q5:'0',q6:'0',q7:'0',pq:'0'}));
            }
            else if(quesCheck1.check === '1' && quesCheck2.check === '1' && quesCheck3.check === '1' && quesCheck4.check === '1' &&
                quesCheck5.check === '0' && quesCheck6.check === '0' && quesCheck7.check === '0') {
                history.push('/dbda_ma');
                sessionStorage.setItem('Qpoints',JSON.stringify({q1p1:'1',q1p2:'1',q2:'1',q3:'1',q4:'1',q5:'0',q6:'0',q7:'0',pq:'0'}));
            }
            else if(quesCheck1.check === '1' && quesCheck2.check === '1' && quesCheck3.check === '1' && quesCheck4.check === '1' &&
                quesCheck5.check === '1' && quesCheck6.check === '0' && quesCheck7.check === '0') {
                history.push('/dbda_cl');
                sessionStorage.setItem('Qpoints',JSON.stringify({q1p1:'1',q1p2:'1',q2:'1',q3:'1',q4:'1',q5:'1',q6:'0',q7:'0',pq:'0'}));
            }
            else if(quesCheck1.check === '1' && quesCheck2.check === '1' && quesCheck3.check === '1' && quesCheck4.check === '1' &&
                quesCheck5.check === '1' && quesCheck6.check === '1' && quesCheck7.check === '0') {
                history.push('/dbda_ra');
                sessionStorage.setItem('Qpoints',JSON.stringify({q1p1:'1',q1p2:'1',q2:'1',q3:'1',q4:'1',q5:'1',q6:'1',q7:'0',pq:'0'}));
            }
            else if(quesCheck1.check === '1' && quesCheck2.check === '1' && quesCheck3.check === '1' && quesCheck4.check === '1' &&
                quesCheck5.check === '1' && quesCheck6.check === '1' && quesCheck7.check === '1') {
                history.push('/personalitytest');
                sessionStorage.setItem('Qpoints',JSON.stringify({q1p1:'1',q1p2:'1',q2:'1',q3:'1',q4:'1',q5:'1',q6:'1',q7:'1',pq:'0'}));
            }
            else {
                history.push('/intro');
                sessionStorage.setItem('Qpoints',JSON.stringify({q1p1:'0',q1p2:'0',q2:'0',q3:'0',q4:'0',q5:'0',q6:'0',q7:'0',pq:'0'}));
            }
        }
        
    }, [userDetailData,payCheck.check,history,quesCheck1,quesCheck2,quesCheck3,quesCheck4,quesCheck5,quesCheck6,quesCheck7])

    const nextRoute = () => {
        history.push('/dbda_va1')
    }

    const title = "Intro";
    const url = "https://careers4me.in/videos/Intro.mp4";

    return (
        /* <Videos title={title} url={url} onPress={nextRoute} Loading={Loading}>            
        </Videos> */

        <Fragment>
            <CssBaseline />
            <Container maxWidth="lg">
                <Topbar
                    userData={userData}
                    handleMenu={handleMenu}
                    anchorEl={anchorEl}
                    open={open}
                    handleClose={handleClose}
                    handleOpenModal={handleOpenModal}
                    logout={logout}
                    openmodal={openmodal}
                    handleCloseModal={handleCloseModal}
                    updateProfile={updateProfile}
                    handleChange={handleProfileChange}
                />
                <main style={{ marginTop: "20px", marginBottom: "20px" }}>
                    <Paper elevation={2} style={{ padding: "20px 0px" }}>
                        <Fragment>
                            <div>
                                <Typography variant="h4" color="inherit" >{title}</Typography>
                                <Grid container direction="row" spacing={2} justify="center" alignItems="center" className="Questions">
                                    <ReactPlayer url={url} controls width="680px" height="400px" />
                                </Grid>
                            </div>
                            <div>
                                <Fragment>
                                    <Button variant="contained" color="primary" onClick={nextRoute}>Next</Button>
                                </Fragment>
                            </div>
                        </Fragment>
                    </Paper>
                    <Footer />
                </main>
            </Container>
            <Fragment>
                {
                    Loading ? <div id="preloader"></div> : ""
                }
            </Fragment>
        </Fragment>
    )
});

export default Intro