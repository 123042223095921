import { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom';

const useAuth = () => {

    const [token, setToken] = useState('');
    const history = useHistory();

    /* const [isLoading, setIsLoading] = useState(false);
    const [classes, setClasses] = useState('d-none');
    const [response, setResopnse] = useState('');

    const setMessages = (msg = false, classes = false, bool = false) => {
        setResopnse(msg);
        setClasses(classes);
        setIsLoading(bool);
    } */

    function randomString(length, chars) {
        var result = '';
        for (var i = length; i > 0; --i) result += chars[Math.floor(Math.random() * chars.length)];
        return result;
    }
    var rString = randomString(64, '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ');


    useEffect(() => {
        if (localStorage.getItem('token') !== null && localStorage.getItem('userData') !== null) {
            history.push('/');
        }
        sessionStorage.setItem("token", rString);
        setToken(rString);
        // eslint-disable-next-line
    }, [history]);

    return { token }

}

export default useAuth
