import React, { Fragment, useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { Typography, Grid, Radio, RadioGroup, FormControlLabel, FormControl, Button } from '@material-ui/core';
import Questions from '../Questions';
import Timer from '../Timer';
import Constants from '../../Constants'
import useProfile from '../../Hooks/useProfile'
import Quiz from '../Quiz';
import { toast } from 'react-toastify'

const QuizCompo = React.memo(() => {

    const history = useHistory();

    const [question1, setQuestion1] = useState([]);
    const [disableQuestion, setDisableQuestion] = useState(false);

    const { loader, showLoader, hideLoader } = useProfile();

    // Set Answer1 State
    const [answer1, setAnswer1] = useState({
        question1: "", question2: "", question3: "", question4: "", question5: "", question6: "", question7: "",
        question8: "", question9: "", question10: "", question11: "", question12: "", question13: "", question14: "", question15: "",
        ans1: "b", ans2: "d", ans3: "c", ans4: "e", ans5: "c", ans6: "a", ans7: "b",
        ans8: "b", ans9: "d", ans10: "b", ans11: "e", ans12: "e", ans13: "a", ans14: "d", ans15: "c"
    });

    // HandleChange Input1
    const handleChange = (e) => {
        setAnswer1((prevProps) => ({
            ...prevProps,
            [e.target.name]: e.target.value
        }));
    }

    const submitHandler = async () => {
        showLoader();
        const response = await fetch(Constants.url + 'questions/question1', {
            method: 'POST',
            headers: {
                'Content-type': 'application/json',
                'Authorization': `Bearer ${JSON.parse(localStorage.getItem('token'))}`
            },
            body: JSON.stringify(answer1)
        });
        const result = await response.json();
        if (result.status === 'success') {
            history.push('/dbda_va2')
            sessionStorage.setItem('Qpoints',JSON.stringify({q1p1:'1',q1p2:'0',q2:'0',q3:'0',q4:'0',q5:'0',q6:'0',q7:'0',pq:'0'}));
            hideLoader()
            toast.success(result.message);
        }
        else {
            hideLoader()
            toast.error(result.message);
            return false;
        }
    }

    useEffect(() => {
        setQuestion1(Questions['DBDA-VA PART-I'])
    },[])

    return (
        <Fragment>
            <div>
                <Typography variant="h6" color="inherit" >VA-Verbal ability</Typography>
                <Timer initialMinute="4" initialSeconds="1" setDisableQuestion={setDisableQuestion} hideonCheck="0"></Timer>
                <Grid container direction="row" spacing={2} justify="center" alignItems="center" className="row_base Questions">
                    {
                        question1.map((item, index) => (
                            <Fragment key={index} >
                                <Grid item xs={6} sm={4} md={3} lg={2}>
                                    <Typography variant="h6" color="inherit"><span className="me-3 dot">{item.id}.</span> {item.question}</Typography>
                                </Grid>
                                <Grid item xs={6} sm={8} md={9} lg={10}>
                                    <FormControl component="fieldset">
                                        <RadioGroup aria-label={`question${item.id}`} name={`question${item.id}`} value={answer1.question} onChange={handleChange}>
                                            <FormControlLabel value='a' control={<Radio />} label={item.a} disabled={disableQuestion} />
                                            <FormControlLabel value='b' control={<Radio />} label={item.b} disabled={disableQuestion} />
                                            <FormControlLabel value='c' control={<Radio />} label={item.c} disabled={disableQuestion} />
                                            <FormControlLabel value='d' control={<Radio />} label={item.d} disabled={disableQuestion} />
                                            <FormControlLabel value='e' control={<Radio />} label={item.e} disabled={disableQuestion} />
                                        </RadioGroup>
                                    </FormControl>
                                </Grid>
                            </Fragment>
                        ))
                    }

                </Grid>
            </div>
            <div>
                <Fragment>
                    <Button variant="contained" color="primary" onClick={submitHandler}>
                        Next</Button>
                </Fragment>
            </div>
            {loader}
        </Fragment>
    )
});

const Question1 = React.memo(() => {

    return (
        <Quiz QuizCompo={QuizCompo} />
    )
});

export default Question1
